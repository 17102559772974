'use client';

import { useContext } from 'react';

import { SheetContext } from './SheetProvider';

export default function () {
  const value = useContext(SheetContext);
  if (value === undefined) throw new Error('context must be within provider');
  return value;
}
