import { Address } from 'viem';

import { cn, shortenAddress } from '@/core/util';

interface AddressDisplayProps {
  address?: Address;
  className?: string;
}

export function AddressDisplay({ address, className }: AddressDisplayProps) {
  return <span className={cn('tabular-nums', className)}>{shortenAddress(address)}</span>;
}
