export const oldColors = {
  blackOpacity: {
    2: 'rgba(0, 0, 0, 0.02)',
    4: 'rgba(0, 0, 0, 0.04)',
    8: 'rgba(0, 0, 0, 0.08)',
    16: 'rgba(0, 0, 0, 0.16)',
    24: 'rgba(0, 0, 0, 0.24)',
    44: 'rgba(0, 0, 0, 0.44)',
    56: 'rgba(0, 0, 0, 0.56)',
    68: 'rgba(0, 0, 0, 0.68)',
    76: 'rgba(0, 0, 0, 0.76)',
    92: 'rgba(0, 0, 0, 0.92)',
  },
  whiteOpacity: {
    4: 'rgba(255, 255, 255, 0.04)',
    8: 'rgba(255, 255, 255, 0.08)',
    16: 'rgba(255, 255, 255, 0.16)',
    24: 'rgba(255, 255, 255, 0.24)',
    44: 'rgba(255, 255, 255, 0.44)',
    56: 'rgba(255, 255, 255, 0.56)',
    68: 'rgba(255, 255, 255, 0.68)',
    76: 'rgba(255, 255, 255, 0.76)',
    84: 'rgba(255, 255, 255, 0.84)',
    92: 'rgba(255, 255, 255, 0.92)',
    98: 'rgba(255, 255, 255, 0.98)',
  },
  primaryOpacity: {
    4: 'rgba(26, 12, 109, 0.04)',
    8: 'rgba(26, 12, 109, 0.08)',
    12: 'rgba(26, 12, 109, 0.12)',
    16: 'rgba(26, 12, 109, 0.16)',
    24: 'rgba(26, 12, 109, 0.24)',
    48: 'rgba(26, 12, 109, 0.48)',
    64: 'rgba(26, 12, 109, 0.64)',
    84: 'rgba(26, 12, 109, 0.84)',
    92: 'rgba(26, 12, 109, 0.92)',
    100: 'rgba(26, 12, 109, 1)',
  },
  accentOpacity: {
    4: 'rgba(202, 223, 255, 0.04)',
    8: 'rgba(202, 223, 255, 0.08)',
    12: 'rgba(202, 223, 255, 0.12)',
    16: 'rgba(202, 223, 255, 0.16)',
    24: 'rgba(202, 223, 255, 0.24)',
    48: 'rgba(202, 223, 255, 0.48)',
    64: 'rgba(202, 223, 255, 0.64)',
    84: 'rgba(202, 223, 255, 0.84)',
    92: 'rgba(202, 223, 255, 0.92)',
    100: 'rgba(202, 223, 255, 1)',
  },
  neutrals: {
    100: '#f0f0f2',
    200: '#e1e2e4',
    300: '#c3c4ca',
    400: '#a5a7af',
    500: '#878994',
    600: '#6b6d78',
    700: '#565760',
    800: '#404148',
    900: '#2b2c30',
    1000: '#1D1E21',
  },
  purple: {
    100: '#EBEBFF',
    200: '#CECDFE',
    300: '#A19FF9',
    400: '#8986FF',
    500: '#4643D6',
    600: '#2F1BA1',
    700: '#1A0C6D',
    800: '#0E0548',
  },
  blue: {
    100: '#EAF2FF',
    200: '#CADFFF',
    300: '#9FB9F9',
    400: '#6A92F6',
    500: '#3163E3',
    600: '#1946B8',
    700: '#0F296C',
    800: '#09183F',
  },
  orange: {
    100: '#FFF6E0',
    200: '#FFE8AD',
    300: '#FFD466',
    400: '#FFB800',
    500: '#E09900',
    600: '#946A00',
    700: '#473300',
    800: '#1F1600',
  },
  green: {
    100: '#F4FFE5',
    200: '#D7FFA3',
    300: '#BEFF6B',
    400: '#8FFF00',
    500: '#0EAA00',
    600: '#0A7A00',
    700: '#075200',
    800: '#043300',
  },
  beige: {
    100: '#FBF7EF',
    200: '#F7EFDE',
    300: '#EBD7AD',
    400: '#D9B363',
    500: '#73571C',
    600: '#5A4416',
    700: '#423210',
    800: '#251C09',
  },
  red: {
    100: '#FFEDEB',
    200: '#FDA9A0',
    300: '#F88073',
    400: '#E95949',
    500: '#A81B0B',
    600: '#861F13',
    700: '#59150D',
    800: '#310B07',
  },
  background: {
    primary: '#1A0C6D',
    secondary: '#271C7C',
    tertiary: '#353494',
    quaternary: '#0E0548',
  },
  interactivePrimary: {
    bg: '#CADFFF',
    hover: '#A7BFFC',
    focus: '#A6C0FC',
    press: '#94B0FA',
  },
  interactivePrimaryDark: {
    bg: '',
  },
  borderSelected: {
    default: '#CADFFF',
    hover: '#B0C7FD',
    press: '#94B0FA',
  },
};

export const colors = {
  blue: {
    50: 'rgb(244, 244, 247)',
    100: 'rgb(238, 237, 245)',
    200: 'rgb(214, 212, 229)',
    300: 'rgb(181, 176, 209)',
    400: 'rgb(141, 134, 178)',
    500: 'rgb(106, 97, 153)',
    600: 'rgb(69, 58, 128)',
    700: 'rgb(33, 21, 105)',
    800: 'rgb(28, 17, 87)',
    900: 'rgb(22, 14, 69)',
    950: 'rgb(16, 11, 51)',
  },
  emerald: {
    50: 'rgb(235, 255, 229)',
    100: 'rgb(209, 255, 199)',
    200: 'rgb(166, 255, 149)',
    300: 'rgb(109, 254, 88)',
    400: 'rgb(63, 245, 38)',
    500: 'rgb(27, 220, 6)',
    600: 'rgb(14, 170, 0)',
    700: 'rgb(14, 133, 6)',
    800: 'rgb(17, 105, 11)',
    900: 'rgb(17, 88, 15)',
    950: 'rgb(1, 50, 1)',
  },
  lavender: {
    50: 'rgb(237, 239, 255)',
    100: 'rgb(222, 226, 255)',
    200: 'rgb(196, 201, 255)',
    300: 'rgb(160, 166, 255)',
    400: 'rgb(137, 134, 255)',
    500: 'rgb(107, 91, 249)',
    600: 'rgb(92, 61, 238)',
    700: 'rgb(79, 47, 211)',
    800: 'rgb(64, 41, 170)',
    900: 'rgb(55, 41, 134)',
    950: 'rgb(34, 24, 78)',
  },
  red: {
    50: 'rgb(255, 240, 242)',
    100: 'rgb(255, 227, 230)',
    200: 'rgb(255, 203, 210)',
    300: 'rgb(255, 160, 175)',
    400: 'rgb(255, 107, 133)',
    500: 'rgb(251, 56, 94)',
    600: 'rgb(235, 33, 83)',
    700: 'rgb(197, 11, 61)',
    800: 'rgb(165, 12, 58)',
    900: 'rgb(141, 14, 57)',
    950: 'rgb(79, 2, 26)',
  },
  mustard: {
    50: 'rgb(250, 249, 235)',
    100: 'rgb(250, 244, 195)',
    200: 'rgb(250, 240, 135)',
    300: 'rgb(250, 228, 70)',
    400: 'rgb(252, 214, 30)',
    500: 'rgb(255, 184, 0)',
    600: 'rgb(226, 141, 0)',
    700: 'rgb(187, 99, 2)',
    800: 'rgb(152, 76, 8)',
    900: 'rgb(124, 63, 11)',
    950: 'rgb(72, 32, 0)',
  },
  manilla: {
    50: 'rgb(253, 251, 247)',
    100: 'rgb(250, 244, 233)',
    200: 'rgb(247, 239, 222)',
    300: 'rgb(230, 218, 191)',
    400: 'rgb(200, 189, 164)',
    500: 'rgb(170, 159, 137)',
    600: 'rgb(138, 129, 111)',
    700: 'rgb(111, 104, 89)',
    800: 'rgb(86, 81, 69)',
    900: 'rgb(62, 58, 50)',
    950: 'rgb(37, 35, 30)',
  },
  lightblue: {
    50: 'rgb(239, 245, 255)',
    100: 'rgb(233, 242, 255)',
    200: 'rgb(223, 236, 255)',
    300: 'rgb(212, 229, 255)',
    400: 'rgb(202, 223, 255)',
    500: 'rgb(178, 201, 235)',
    600: 'rgb(151, 172, 204)',
    700: 'rgb(121, 138, 166)',
    800: 'rgb(81, 95, 115)',
    900: 'rgb(53, 63, 77)',
    950: 'rgb(27, 31, 38)',
  },
  white: {
    DEFAULT: 'rgba(255, 255, 255, 1)',
    50: 'rgba(255, 255, 255, 0.05)',
    100: 'rgba(255, 255, 255, 0.1)',
    200: 'rgba(255, 255, 255, 0.2)',
    300: 'rgba(255, 255, 255, 0.3)',
    400: 'rgba(255, 255, 255, 0.4)',
    500: 'rgba(255, 255, 255, 0.5)',
    600: 'rgba(255, 255, 255, 0.6)',
    700: 'rgba(255, 255, 255, 0.7)',
    800: 'rgba(255, 255, 255, 0.8)',
    900: 'rgba(255, 255, 255, 0.9)',
    950: 'rgba(255, 255, 255, 0.95)',
  },
  black: {
    DEFAULT: 'rgba(0, 0, 0, 1)',
    50: 'rgba(0, 0, 0, 0.05)',
    100: 'rgba(0, 0, 0, 0.1)',
    200: 'rgba(0, 0, 0, 0.2)',
    300: 'rgba(0, 0, 0, 0.3)',
    400: 'rgba(0, 0, 0, 0.4)',
    500: 'rgba(0, 0, 0, 0.5)',
    600: 'rgba(0, 0, 0, 0.6)',
    700: 'rgba(0, 0, 0, 0.7)',
    800: 'rgba(0, 0, 0, 0.8)',
    900: 'rgba(0, 0, 0, 0.9)',
    950: 'rgba(0, 0, 0, 0.95)',
  },
};

export const SEMANTIC_COLORS = {
  // **FOREGROUND** - text, icons, etc

  // primary text - display, headings
  primaryFg: oldColors.whiteOpacity[98],
  // secondary text - labels, paragraphs, button labels
  secondaryFg: oldColors.whiteOpacity[92],
  // tertiary text - secondary paragraph text, captions
  tertiaryFg: oldColors.whiteOpacity[84],
  quarternaryFg: oldColors.whiteOpacity[68],

  // inverse primary - text on light bgs
  inversePrimaryFg: oldColors.primaryOpacity[100],
  // inverse secondary
  inverseSecondaryFg: oldColors.primaryOpacity[84],
  // inverse tertiary
  inverseTertiaryFg: oldColors.primaryOpacity[64],

  // interactivePrimary - text links
  interactivePrimaryFg: oldColors.green[400],
  interactivePrimaryFgHover: oldColors.green[300],
  interactivePrimaryFgFocus: oldColors.green[300],
  interactivePrimaryFgPress: oldColors.green[200],
  // interactiveSecondary - text links that start as white
  interactiveSecondaryFg: oldColors.green[400],
  interactiveSecondaryFgFocus: oldColors.green[400],
  interactiveSecondaryFgPress: oldColors.green[300],

  // disabled
  disabledFg: oldColors.whiteOpacity[24],
  inverseDisabledFg: oldColors.blackOpacity[16],

  // system
  // danger - destructive text, links, buttons
  dangerPrimaryFg: oldColors.red[500],
  dangerPrimaryFgHover: oldColors.red[600],
  dangerPrimaryFgFocus: oldColors.red[600],
  dangerPrimaryFgPress: oldColors.red[700],
  dangerSecondaryFg: oldColors.red[400],
  dangerSecondaryFgHover: oldColors.red[300],
  dangerSecondaryFgFocus: oldColors.red[300],
  dangerSecondaryFgPress: oldColors.red[200],
  // success
  successFg: oldColors.green[400],
  successFgHover: oldColors.green[300],
  successFgFocus: oldColors.green[300],
  successFgPress: oldColors.green[200],
  // warning
  warningFg: oldColors.orange[500],
  warningFgHover: oldColors.orange[300],
  warningFgFocus: oldColors.orange[300],
  warningFgPress: oldColors.orange[200],

  // accent
  // accentPrimary - badges, text on accent bg
  accentPrimaryFg: oldColors.primaryOpacity[100],
  accentSecondaryFg: oldColors.primaryOpacity[84],

  // **BACKGROUND**

  // base bg
  baseBg: oldColors.purple[800],
  // card bg
  primaryBg: oldColors.purple[700],
  // secondary bg (card headings)
  secondaryBg: oldColors.background.secondary,
  // tertiary bg (card subheadings/table headings)
  tertiaryBg: oldColors.background.tertiary,
  // quarternary bg
  quarternaryBg: oldColors.background.quaternary,
  // information icons, progress bar, pending payments
  opacityPrimaryBg: oldColors.blackOpacity[16],
  // information icons
  opacitySecondaryBg: oldColors.whiteOpacity[4],
  // overlays for modals/sheets
  overlayBg: oldColors.blackOpacity[24],
  overlayDarkBg: oldColors.blackOpacity[44],

  // **INPUT CONTROLS**

  // input fields, checkboxes, radios, etc
  inputBg: oldColors.whiteOpacity[8],
  inputHover: oldColors.whiteOpacity[16],
  inputFocus: oldColors.whiteOpacity[16],
  // selected - checkbox, radio, toggle
  selected: oldColors.interactivePrimary.bg,
  selectedHover: oldColors.interactivePrimary.hover,
  selectedFocus: oldColors.interactivePrimary.focus,
  selectedPress: oldColors.interactivePrimary.press,
  // selectedSecondary - super radios, larger components
  selectedSecondary: oldColors.accentOpacity[12],
  selectedSecondaryHover: oldColors.accentOpacity[24],
  selectedSecondaryFocus: oldColors.accentOpacity[24],
  selectedSecondaryPress: oldColors.accentOpacity[48],

  // **INTERACTIVE: buttons/actions and intents

  // primary - primary buttons
  interactivePrimary: oldColors.interactivePrimary.bg,
  interactivePrimaryHover: oldColors.interactivePrimary.hover,
  interactivePrimaryFocus: oldColors.interactivePrimary.focus,
  interactivePrimaryPress: oldColors.interactivePrimary.press,
  // primary on light - TODO

  // secondary - secondary buttons, actions
  interactiveSecondary: oldColors.blackOpacity[24],
  interactiveSecondaryHover: oldColors.blackOpacity[44],
  interactiveSecondaryFocus: oldColors.blackOpacity[44],
  interactiveSecondaryPress: oldColors.blackOpacity[68],
  interactiveSecondaryActive: oldColors.accentOpacity[16],
  // secondary on light - TODO

  // tertiary - tertiary buttons, actions
  interactiveTertiary: 'transparent',
  interactiveTertiaryHover: oldColors.blackOpacity[4],
  interactiveTertiaryFocus: oldColors.blackOpacity[4],
  interactiveTertiaryPress: oldColors.blackOpacity[8],
  interactiveTertiaryActive: oldColors.accentOpacity[48],

  // quarternary - quarternary buttons, actions
  interactiveQuarternary: oldColors.accentOpacity[8],
  interactiveQuarternaryHover: oldColors.accentOpacity[24],
  interactiveQuarternaryFocus: oldColors.accentOpacity[24],
  interactiveQuarternaryPress: oldColors.accentOpacity[48],

  // disabled buttons
  disabled: oldColors.whiteOpacity[4],
  disabledLight: oldColors.blackOpacity[4],

  // danger primary
  dangerPrimary: oldColors.red[100],
  dangerPrimaryHover: oldColors.red[200],
  dangerPrimaryFocus: oldColors.red[200],
  dangerPrimaryPress: oldColors.red[300],

  // informative
  info: oldColors.blue[200],
  success: oldColors.green[100],
  warning: oldColors.orange[100],
  danger: oldColors.red[100],

  // accents - brand moments
  accentPurpleDark: oldColors.purple[500],
  accentPurple: oldColors.purple[300],
  accentPurpleLight: oldColors.purple[200],
  accentBlueDark: oldColors.blue[500],
  accentBlue: oldColors.blue[200],
  accentOrange: oldColors.orange[400],
  accentBeige: oldColors.beige[100],
  accentGreen: oldColors.green[400],
  accentWhite: '#ffffff',

  // **BORDERS**

  // bounds
  // bounding stroke around containers
  borderBounds: oldColors.accentOpacity[16],
  borderDivider: oldColors.whiteOpacity[8],

  // focus state on all actionable items
  borderFocus: oldColors.green[400],

  // disabled
  borderDisabled: oldColors.whiteOpacity[8],
  borderDisabledLight: oldColors.blackOpacity[8],

  // input controls
  borderInput: oldColors.whiteOpacity[16],
  borderInputHover: oldColors.whiteOpacity[44],
  borderInputPress: oldColors.whiteOpacity[56],
  borderInputActive: oldColors.green[400],

  // selected
  borderSelected: oldColors.borderSelected.default,
  borderSelectedHover: oldColors.borderSelected.hover,
  borderSelectedPress: oldColors.borderSelected.press,

  // system
  borderInfo: oldColors.borderSelected.default,
  borderDanger: oldColors.red[400],
  borderSuccess: oldColors.green[400],
  borderWarning: oldColors.orange[400],

  // accents
  borderAccentPurple: oldColors.purple[300],
  borderAccentBlue: oldColors.blue[200],
  borderAccentOrange: oldColors.orange[400],
  borderAccentBeige: oldColors.beige[100],
  borderAccentGreen: oldColors.green[400],
};

export const TAILWIND_FONT_SIZES = {
  Display3XL: [
    '120px',
    {
      lineHeight: '120px',
      letterSpacing: '-2.4px',
    },
  ],
  Display2XL: [
    '72px',
    {
      lineHeight: '92px',
      letterSpacing: '-1.44px',
    },
  ],
  DisplayXL: [
    '60px',
    {
      lineHeight: '72px',
      letterSpacing: '-1.2px',
    },
  ],
  DisplayL: [
    '48px',
    {
      lineHeight: '60px',
      letterSpacing: '-0.96px',
    },
  ],
  DisplayM: [
    '36px',
    {
      lineHeight: '44px',
      letterSpacing: '-0.72px',
    },
  ],
  DisplayS: [
    '30px',
    {
      lineHeight: '40px',
      letterSpacing: '-0.6px',
    },
  ],
  DisplayXS: [
    '24px',
    {
      lineHeight: '32px',
      letterSpacing: '-0.48px',
    },
  ],
  TextXL: [
    '20px',
    {
      lineHeight: '32px',
      letterSpacing: '-0.4px',
    },
  ],
  TextL: [
    '18px',
    {
      lineHeight: '28px',
      letterSpacing: '-0.36px',
    },
  ],
  TextM: [
    '16px',
    {
      lineHeight: '24px',
      letterSpacing: '-0.32px',
    },
  ],
  TextS: [
    '14px',
    {
      lineHeight: '20px',
      letterSpacing: '-0.28px',
    },
  ],
  TextXS: [
    '12px',
    {
      lineHeight: '16px',
      letterSpacing: '-0.24px',
    },
  ],
} as const;

export const KEYFRAMES = {
  toastSlideRight: {
    '0%': { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
    '100%': { transform: 'translateX(100%)' },
  },
  fadeIn: {
    '0%': { opacity: '0', transform: 'translateY(10%)' },
    '100%': { opacity: '1', transform: 'translateY(0)' },
  },
  fadeOut: {
    '0%': { opacity: '1', transform: 'translateY(0)' },
    '100%': { opacity: '0', transform: 'translateY(10%)' },
  },
};

export const ANIMATION = {
  fadeIn: 'fadeIn 0.3s ease-out forwards',
  fadeOut: 'fadeOut 0.3s ease-out forwards',
};

export const FONT_FAMILIES = {
  abcRepro: [
    'var(--font-abc-repro)',
    {
      fontFeatureSettings: '"ss03" 1, "ss10" 1, "ss11" 1, "liga" 1, "calt" 1',
    },
  ],
  ibmPlexMono: ['var(--font-ibm-plex-mono)'],
};
