'use client';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import { TextV2 } from '../../foundations/V2/Text';

export interface TooltipProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
  delayDuration?: number;
  triggerClassName?: string;
  contentClassName?: string;
  arrowClassName?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  side?: 'top' | 'bottom' | 'left' | 'right';
  sideOffset?: number;
  align?: 'start' | 'center' | 'end';
  alignOffset?: number;
  asChild?: boolean;
  displayArrow?: boolean;
}

export function Tooltip({
  trigger,
  content,
  delayDuration = 300,
  triggerClassName,
  contentClassName,
  arrowClassName,
  open,
  onOpenChange,
  side,
  sideOffset,
  align,
  alignOffset,
  asChild = false,
  displayArrow = true,
}: TooltipProps) {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root open={open} onOpenChange={onOpenChange} delayDuration={delayDuration}>
        <RadixTooltip.Trigger className={triggerClassName} asChild={asChild}>
          {trigger}
        </RadixTooltip.Trigger>
        <RadixTooltip.Content
          className={`align-center bg-interactivePrimary text-accentPrimaryFg flex items-center rounded p-2 ${contentClassName}`}
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
        >
          {displayArrow && (
            <RadixTooltip.Arrow
              className={`fill-interactivePrimary bg-transparent ${arrowClassName}`}
            />
          )}
          <TextV2 intent="TextXS">{content}</TextV2>
        </RadixTooltip.Content>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
