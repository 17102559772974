import React, { isValidElement } from 'react';

import { cn } from '@/core/util';

export interface ZebraTableProps {
  items: React.ReactNode[];
  tableClassName?: string;
  rowClassName?: string;
}

export function ZebraTable({ items, tableClassName, rowClassName }: ZebraTableProps) {
  return (
    <div className={`flex flex-col ${tableClassName}`}>
      {items.map((item, idx) => {
        const isEven = idx % 2 === 0;
        return (
          <div
            key={isValidElement(item) ? item.key : idx}
            className={cn(
              isEven
                ? 'bg-interactiveQuarternary hover:bg-interactiveQuarternaryHover'
                : 'hover:bg-interactiveQuarternaryHover bg-lightblue-100',
              rowClassName,
            )}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
}
