export function EtherscanLogo({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M3.32537 7.60494C3.32538 7.51547 3.34304 7.42689 3.37732 7.34427C3.41161 7.26167 3.46183 7.18667 3.52513 7.12355C3.58844 7.06044 3.66355 7.01048 3.74618 6.97653C3.8288 6.94259 3.91731 6.92533 4.00662 6.92575L5.13611 6.92945C5.31619 6.92945 5.48891 7.00111 5.61623 7.12867C5.74358 7.25624 5.81512 7.42926 5.81512 7.60968V11.8881C5.94227 11.8503 6.10558 11.81 6.28428 11.7679C6.40841 11.7387 6.51903 11.6683 6.59821 11.5682C6.67739 11.4681 6.72045 11.3441 6.72043 11.2163V5.90909C6.72043 5.72866 6.79196 5.55563 6.9193 5.42804C7.04663 5.30045 7.21934 5.22876 7.39944 5.22871H8.53117C8.71126 5.22876 8.88397 5.30045 9.01131 5.42804C9.13865 5.55563 9.21018 5.72866 9.21018 5.90909V10.8348C9.21018 10.8348 9.49357 10.7199 9.7696 10.6031C9.87203 10.5597 9.9596 10.4871 10.0211 10.394C10.0826 10.3012 10.1155 10.1922 10.1156 10.0806V4.2085C10.1156 4.02811 10.1871 3.8551 10.3145 3.72754C10.4418 3.59997 10.6144 3.52831 10.7945 3.52826H11.9263C12.1063 3.52826 12.2791 3.59992 12.4063 3.7275C12.5337 3.85507 12.6053 4.02808 12.6053 4.2085V9.04404C13.5865 8.33167 14.5807 7.47492 15.3698 6.44468C15.4844 6.29515 15.5601 6.11954 15.5903 5.93356C15.6206 5.74757 15.6044 5.55697 15.5432 5.37876C15.1779 4.32597 14.5975 3.36116 13.8387 2.54606C13.0798 1.73098 12.1595 1.08354 11.1365 0.645226C10.1137 0.206899 9.01068 -0.012673 7.8982 0.000564869C6.78572 0.0138028 5.68826 0.25955 4.67603 0.722086C3.6638 1.18462 2.75909 1.85375 2.01982 2.68667C1.28055 3.51958 0.722982 4.49794 0.382805 5.55913C0.0426285 6.6203 -0.0726684 7.74094 0.0442939 8.84933C0.161256 9.95766 0.50789 11.0294 1.06203 11.9959C1.15857 12.1626 1.30059 12.2982 1.47141 12.3869C1.64223 12.4756 1.83475 12.5136 2.02639 12.4965C2.24045 12.4777 2.50698 12.4509 2.82387 12.4136C2.96181 12.3979 3.08917 12.332 3.18168 12.2283C3.27419 12.1246 3.32538 11.9904 3.3255 11.8513L3.32537 7.60494Z"
        fill="currentColor"
      />
      <path
        d="M3.30078 14.4674C4.49369 15.3368 5.90346 15.8586 7.37408 15.9753C8.84471 16.0917 10.3188 15.7985 11.6334 15.1277C12.948 14.4569 14.0516 13.435 14.8224 12.1749C15.5931 10.9147 16.0009 9.46554 16.0004 7.98769C16.0004 7.80327 15.9919 7.62084 15.9797 7.43945C13.0581 11.8046 7.66349 13.8461 3.30078 14.4674Z"
        fill="currentColor"
      />
    </svg>
  );
}
