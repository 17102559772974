import React, { ComponentProps, ReactNode } from 'react';

import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import * as Select from '@radix-ui/react-select';
import { cva, VariantProps } from 'class-variance-authority';

import { cn, Prettify } from '@/core/util';

import { TextV2 } from '../../foundations/V2/Text';
import { ComponentSize } from '../../types';

const selectContainer = cva(
  'flex items-center caret-accentGreen bg-inputBg hover:bg-inputHover focus-within:bg-inputFocus rounded text-blue-700 border-1 border-transparent outline-none focus-within:outline-1 focus-within:outline-accentGreen active:bg-inputFocus focus-within:bg-lightblue-100 placeholder:text-disabledFg font-abcRepro focus-within:shadow-[0px_0px_0px_4px_rgba(255,255,255,0.24)]',
  {
    variants: {
      size: {
        [ComponentSize.SM]: ['gap-2 px-4 py-2 text-TextS'],
        [ComponentSize.MD]: ['gap-2 px-4 py-3 text-TextM'],
        [ComponentSize.LG]: ['gap-4 p-4 text-TextL'],
      },
    },
    defaultVariants: {
      size: ComponentSize.MD,
    },
  },
);

type Variants = VariantProps<typeof selectContainer>;

type RootProps = Omit<ComponentProps<typeof Select.Root>, 'children'>;

type SelectComponentProps = Prettify<
  {
    placeholder?: string;
    ariaLabel?: string;
    items:
      | Array<{
          value: string;
          display: ReactNode;
          disabled?: boolean;
        }>
      | ReactNode;
  } & RootProps &
    Variants
>;

export function SelectComponent({
  placeholder,
  ariaLabel,
  items,
  size,
  ...props
}: SelectComponentProps) {
  return (
    <Select.Root {...props}>
      <Select.Trigger aria-label={ariaLabel} className={selectContainer({ size })}>
        {props.value ? (
          <Select.Value aria-label={props.value}>{props.value}</Select.Value>
        ) : (
          <Select.Value placeholder={placeholder} />
        )}
        <Select.Icon>
          <ChevronDownIcon className="h-4 w-4" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="border-borderBounds bg-tertiaryBg flex w-full flex-col gap-1 border">
          <Select.ScrollUpButton className="SelectScrollButton"></Select.ScrollUpButton>
          <Select.Viewport className="p-1">
            {Array.isArray(items)
              ? items.map(({ value, display, disabled }) => (
                  <SelectItem value={value} disabled={disabled} key={value}>
                    <TextV2 intent="TextS" font="ibmPlexMono">
                      {display}
                    </TextV2>
                  </SelectItem>
                ))
              : items}
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton"></Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}

// TODO: Add Seperators and Groups

const SelectItem = React.forwardRef<
  React.ElementRef<typeof Select.Item>,
  React.ComponentPropsWithoutRef<typeof Select.Item>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Select.Item
      className={cn('hover:bg-quarternaryBg flex cursor-pointer items-center pl-8 pr-4', className)}
      {...props}
      ref={forwardedRef}
    >
      <Select.ItemIndicator className="absolute left-4 inline-flex items-center justify-center">
        <CheckIcon className="h-4 w-4" />
      </Select.ItemIndicator>
      <Select.ItemText className="self-end">{children}</Select.ItemText>
    </Select.Item>
  );
});

export default SelectComponent;
