'use client';

import {
  ChatBubbleBottomCenterIcon,
  PencilIcon,
  PowerIcon,
  ShareIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Address } from 'viem';

import { cn } from '@/core/util';

import { TextV2 } from '../../foundations/V2/Text';
import { DiscordLogo } from '../../Icons/DiscordLogo';
import { WalletIcon } from '../../Icons/WalletIcon';
import { ComponentSize } from '../../types';
import { Button as ButtonV2 } from '../V2/Button';
import { AddressDisplay } from './AddressDisplay';

const ITEM_CLASS =
  'flex items-center p-2 hover:border-collapse hover:cursor-pointer hover:rounded-lg hover:border-transparent hover:bg-blue-200 outline-none';

function DropdownLink({
  href,
  label,
  icon,
}: {
  href: string;
  label: string;
  icon: React.ReactNode;
}) {
  return (
    <DropdownMenu.Item asChild className={ITEM_CLASS}>
      <a href={href} target="blank" rel="noreferrer">
        {icon}
        <TextV2 intent="TextM" weight="medium">
          {label}
        </TextV2>
      </a>
    </DropdownMenu.Item>
  );
}

export function AddressDropdown({
  address,
  disconnect,
  ensName,
  className,
  blockExplorerURL,
}: {
  address: string;
  disconnect: () => void;
  ensName?: string;
  className?: string;
  blockExplorerURL: string;
}) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <ButtonV2
          intent="secondary"
          size={ComponentSize.SM}
          className={cn(
            'flex w-full cursor-pointer text-nowrap border-transparent font-abcRepro',
            className,
          )}
          asChild
        >
          <div className="flex items-center">
            <WalletIcon className="mr-1.5 h-5 w-5" />
            <TextV2 intent="TextM" weight="medium" className="normal-case">
              {ensName ?? <AddressDisplay address={address as Address} />}
            </TextV2>
          </div>
        </ButtonV2>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-[500] flex w-56 flex-col rounded-xl bg-white p-2 text-blue-800 shadow-2xl"
          sideOffset={5}
          alignOffset={-2}
          align="end"
        >
          <DropdownLink
            href={`${blockExplorerURL}/address/${address}`}
            label="View on explorer"
            icon={<ShareIcon className="mr-4 h-4 w-4" />}
          />
          <DropdownMenu.Item className={ITEM_CLASS} onClick={disconnect}>
            <PowerIcon className="mr-4 h-4 w-4" />
            <TextV2 intent="TextM" weight="medium">
              Disconnect
            </TextV2>
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="m-1 h-[1px] bg-blue-400" />
          <DropdownLink
            href="https://docs.eigenlayer.xyz/overview/readme/"
            label="Documentation"
            icon={<Square2StackIcon className="mr-4 h-4 w-4" />}
          />
          <DropdownLink
            href="https://blog.eigenlayer.xyz/"
            label="Blog"
            icon={<PencilIcon className="mr-4 h-4 w-4" />}
          />
          <DropdownLink
            href="https://discord.gg/eigenlayer"
            label="Discord"
            icon={<DiscordLogo className="mr-4 h-4 w-4" />}
          />
          <DropdownLink
            href="https://forum.eigenlayer.xyz/"
            label="Forum"
            icon={<ChatBubbleBottomCenterIcon className="mr-4 h-4 w-4" />}
          />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
