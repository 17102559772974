'use client';

import { createContext, ReactNode, useReducer } from 'react';

import { cn } from '@/core/util';

import { ToastProvider } from '../Toast';
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
} from './Component';

type SheetProps = {
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
  side: 'top' | 'bottom' | 'left' | 'right';
};

type SheetContextType = {
  showSheet: (sheet: Partial<SheetProps>) => void;
  hideSheet: () => void;
};

export const SheetContext = createContext<SheetContextType>(
  undefined as unknown as SheetContextType,
);

type State = SheetProps & { open: boolean };
type Action =
  | { type: 'HIDE_SHEET' | 'CLEAR_SHEET' }
  | ({ type: 'SHOW_SHEET' } & Partial<SheetProps>);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'HIDE_SHEET': {
      return {
        ...state,
        open: false,
      };
    }

    case 'CLEAR_SHEET': {
      return {
        ...state,
        header: null,
        body: null,
        footer: null,
      };
    }
    case 'SHOW_SHEET': {
      return {
        open: true,
        header: action.header ?? null,
        body: action.body ?? null,
        footer: action.footer ?? null,
        side: action.side ?? 'right',
      };
    }
  }
}

function SheetProvider({ children, className }: { children: ReactNode; className?: string }) {
  const [state, dispatch] = useReducer(reducer, {
    header: null,
    body: null,
    footer: null,
    open: false,
    side: 'right',
  });

  const showSheet = (sheet: Partial<SheetProps>) => {
    dispatch({ type: 'SHOW_SHEET', ...sheet });
  };

  const hideSheet = () => {
    dispatch({ type: 'HIDE_SHEET' });
    setTimeout(() => {
      dispatch({ type: 'CLEAR_SHEET' });
    }, 500);
  };

  return (
    <ToastProvider>
      <Sheet
        open={state.open}
        onOpenChange={(isOpen: boolean) => {
          if (isOpen === false) {
            hideSheet();
          }
        }}
      >
        <SheetContext.Provider value={{ showSheet, hideSheet }}>
          {children}
          <SheetPortal>
            <SheetOverlay />
            <SheetContent
              side={state.side}
              className={cn('h-screen max-h-screen bg-blue-100', className)}
            >
              {state.header && <SheetHeader>{state.header}</SheetHeader>}
              {state.body}
              {state.footer && <SheetFooter>{state.footer}</SheetFooter>}
            </SheetContent>
          </SheetPortal>
        </SheetContext.Provider>
      </Sheet>
    </ToastProvider>
  );
}
export default SheetProvider;
