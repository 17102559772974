'use client';

import { forwardRef, ReactNode } from 'react';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import type { FC } from 'react';

interface TooltipV2Props extends RadixTooltip.TooltipProviderProps, RadixTooltip.TooltipProps {
  children: ReactNode;
}

interface TooltipV2TriggerProps extends RadixTooltip.TooltipTriggerProps {
  children: ReactNode;
}

interface TooltipV2ContentProps extends RadixTooltip.TooltipContentProps {
  children: ReactNode;
}

/* eslint-disable react/function-component-definition */
export const TooltipV2: FC<TooltipV2Props> & {
  Trigger: FC<TooltipV2TriggerProps & React.RefAttributes<HTMLButtonElement>>;
  Content: FC<TooltipV2ContentProps & React.RefAttributes<HTMLDivElement>>;
} = ({ children, ...providerAndRootProps }) => {
  const { delayDuration, skipDelayDuration, disableHoverableContent, ...rootProps } =
    providerAndRootProps;

  return (
    <RadixTooltip.Provider
      delayDuration={delayDuration}
      skipDelayDuration={skipDelayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <RadixTooltip.Root {...rootProps}>{children}</RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};
/* eslint-enable react/function-component-definition */

TooltipV2.Trigger = forwardRef<HTMLButtonElement, TooltipV2TriggerProps>(
  ({ children, ...props }, ref) => (
    <RadixTooltip.Trigger asChild {...props} ref={ref} className="cursor-pointer">
      {children}
    </RadixTooltip.Trigger>
  ),
);

TooltipV2.Content = forwardRef<HTMLDivElement, TooltipV2ContentProps>(
  ({ children, ...props }, ref) => (
    <RadixTooltip.Content
      ref={ref}
      sideOffset={5}
      className="rounded bg-blue-100 p-2 text-white"
      {...props}
    >
      {children}
      <RadixTooltip.Arrow className="fill-current text-blue-100" />
    </RadixTooltip.Content>
  ),
);
