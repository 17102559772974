import { forwardRef } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { ComponentSize } from '../../types';

const buttonCva = cva(
  'h-fit font-ibmPlexMono rounded-full inline-block focus:border-borderFocus focus:shadow-[0px_0px_0px_4px_rgba(255,255,255,0.24)] outline-none',
  {
    variants: {
      intent: {
        primary: [
          'bg-blue-700 active:bg-blue-500 focus:bg-blue-500 hover:bg-blue-500',
          'text-white-950',
        ],
        secondary: [
          'bg-blue-100 hover:bg-blue-200 active:bg-blue-200 focus:bg-blue-200',
          'text-blue-700',
        ],
        tertiary: [
          'bg-interactiveTertiary border-2 border-transparent hover:bg-interactiveTertiaryHover active:bg-interactiveTertiaryPress focus:bg-interactiveTertiaryFocus',
          'text-blue-700',
        ],
        quarternary: [
          'bg-interactiveQuarternary border-2 border-transparent hover:bg-interactiveQuarternaryHover active:bg-interactiveQuarternaryPress focus:bg-interactiveQuarternaryFocus',
          'text-blue-700',
        ],
        accent: [
          'bg-accentOrange border-2 border-transparent hover:bg-accentOrangeHover active:bg-accentOrangePress focus:bg-accentOrangeFocus',
          'text-inversePrimaryFg',
        ],
        danger: [
          'bg-dangerPrimary border-2 border-transparent hover:bg-dangerPrimaryHover active:bg-dangerPrimaryPress focus:bg-dangerPrimaryFocus',
          'text-dangerPrimaryFg hover:text-dangerPrimaryFgHover active:text-dangerPrimaryFgPress focus:text-dangerPrimaryFgFocus',
        ],
        disabled: [
          'bg-blue-200 opacity-50 border-transparent cursor-not-allowed',
          'text-inversePrimaryFg',
        ],
        toggled: ['bg-blue-700 border-2 border-blue-700 cursor-default', 'text-white-950'],
        unToggled: [
          'border-2  active:bg-blue-200 focus:bg-blue-200 hover:bg-blue-200',
          'text-blue-400',
        ],
        connectWallet: [
          'bg-gradient-to-r from-green-400 to-green-300 hover:to-green-200 active:from-green-300 active:to-green-200',
          'border-0',
          'text-inversePrimaryFg',
        ],
      },
      size: {
        [ComponentSize.XS]: ['px-3 py-1.5', 'text-ShortTextXS'],
        [ComponentSize.SM]: ['px-4 py-2', 'text-ShortTextS'],
        [ComponentSize.MD]: ['px-5 py-3', 'text-ShortTextM'],
        [ComponentSize.LG]: ['px-6 py-4', 'text-ShortTextL'],
      },
      width: {
        fitContent: 'w-fit',
        full: 'w-full',
      },
    },
    defaultVariants: {
      intent: 'primary',
      size: ComponentSize.MD,
      width: 'full',
    },
  },
);

type ButtonVariants = VariantProps<typeof buttonCva>;

export interface ButtonProps
  extends ButtonVariants,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'focus'> {
  children: React.ReactNode;
  className?: string;
  stopPropagation?: boolean;
  dataCypress?: string;
}

export const Button = forwardRef(
  (
    {
      children,
      className,
      intent = 'primary',
      size = ComponentSize.MD,
      width = 'full',
      onClick,
      type = 'button',
      disabled = false,
      stopPropagation,
      dataCypress,
      ...props
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <button
      className={buttonCva({
        className,
        intent: disabled ? 'disabled' : intent,
        size,
        width,
      })}
      onClick={(evt) => {
        (evt.target as HTMLButtonElement)?.blur();
        if (stopPropagation) {
          evt.stopPropagation();
          evt.preventDefault();
        }
        onClick?.(evt);
      }}
      type={type}
      disabled={disabled}
      data-cypress={dataCypress}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  ),
);
