import { TextV2 } from '../../foundations/V2/Text';
import useToast from './useToast';

export const useCopyToClipboardToast = ({ text, label }: { text?: string; label?: string }) => {
  const toast = useToast();

  const copyToClipboardToast = async () => {
    if (!text) return;
    await navigator.clipboard.writeText(text);
    toast.info(
      <TextV2 intent="TextS" font="ibmPlexMono">
        {label || 'Address copied!'}
      </TextV2>,
      {
        id: `copy-${text}`,
      },
    );
  };

  return { copyToClipboardToast };
};
