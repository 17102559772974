import { SVGAttributes } from 'react';

import { cn } from '@/core/util';

export function CopyIcon({
  width = 16,
  height = 16,
  className,
  ...props
}: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('-rotate-90', className)}
      {...props}
    >
      <path
        fill="currentColor"
        d="M11 12H1C0.447 12 0 11.553 0 11V1C0 0.448 0.447 0 1 0H11C11.553 0 12 0.448 12 1V11C12 11.553 11.553 12 11 12Z"
      />
      <path fill="currentColor" d="M15 16H4V14H14V4H16V15C16 15.553 15.553 16 15 16Z" />
    </svg>
  );
}
