import { HTMLInputTypeAttribute } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/core/util';

import { ComponentSize } from '../../types';

const inputContainerCva = cva(
  'flex items-center bg-white rounded-full border border-black-100 outline-none focus-within:border-blue-700 focus-within:text-blue-700',
  {
    variants: {
      size: {
        [ComponentSize.SM]: ['px-4 py-2 text-TextS'],
        [ComponentSize.MD]: ['px-4 py-3 text-TextM'],
        [ComponentSize.LG]: ['p-4 text-TextL'],
      },
    },
    defaultVariants: {
      size: ComponentSize.MD,
    },
  },
);

const textInputCva = cva('bg-transparent outline-none w-full', {
  variants: {
    size: {
      [ComponentSize.SM]: [],
      [ComponentSize.MD]: [],
      [ComponentSize.LG]: [],
    },
  },
  defaultVariants: {
    size: ComponentSize.MD,
  },
});

const iconCva = cva('flex items-center justify-center', {
  variants: {
    size: {
      [ComponentSize.SM]: ['h-3.5 w-3.5'],
      [ComponentSize.MD]: ['h-4 w-5'],
      [ComponentSize.LG]: ['h-5 w-5'],
    },
  },
  defaultVariants: {
    size: ComponentSize.MD,
  },
});

type TextInputVariants = VariantProps<typeof inputContainerCva>;

export interface TextInputProps extends TextInputVariants {
  value?: string;
  onChange?: (value: string) => void;
  containerClassName?: string;
  inputClassName?: string;
  iconClassName?: string;
  placeholder?: string;
  icon?: React.ReactNode;
  iconAtEnd?: boolean;
  search?: boolean;
  clearIcon?: boolean;
  onClear?: () => void;
  type?: HTMLInputTypeAttribute;
  min?: string;
  max?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  dataCypress?: string;
  readOnly?: boolean;
}

export function TextInput({
  size,
  value,
  onChange,
  containerClassName,
  inputClassName,
  iconClassName,
  placeholder,
  search,
  icon,
  iconAtEnd = false,
  clearIcon,
  onClear,
  type,
  min,
  max,
  onKeyDown,
  dataCypress,
  readOnly = false,
}: TextInputProps) {
  return (
    <div className={inputContainerCva({ size, className: containerClassName })}>
      {((icon && !iconAtEnd) || search) && (
        <div className={iconCva({ size, className: cn('mr-1.5', iconClassName) })}>
          {search ? (
            <svg
              viewBox="0 0 24 24"
              width="24px"
              height="24px"
              fill="none"
              className="h-full w-full"
            >
              <path
                d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            icon
          )}
        </div>
      )}
      <input
        className={textInputCva({ size, className: inputClassName })}
        value={value}
        onChange={onChange && ((e) => onChange(e.target.value))}
        placeholder={placeholder}
        type={type || 'text'}
        min={min}
        max={max}
        data-cypress={dataCypress}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
      />
      {clearIcon && (
        <div onClick={onClear} className={iconCva({ size, className: 'cursor-pointer' })}>
          <svg viewBox="0 0 24 24" width="24px" height="24px" fill="none" className="h-full w-full">
            <path
              d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      {iconAtEnd && icon && <div className={iconCva({ size })}>{icon}</div>}
    </div>
  );
}
