import { ArrowLeftIcon, ArrowRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

import { cn } from '@/core/util';

import { TextV2 } from '../../../foundations/V2/Text';

export interface PaginationProps {
  totalPages: number;
  pageIndex: number;
  onPageChange: (page: number) => void;
  truncate?: boolean;
}

function WrappedEllipsis() {
  return (
    <div className="flex h-10 w-10 cursor-pointer select-none items-end justify-center rounded-lg py-2 text-blue-400">
      <EllipsisHorizontalIcon className="h-4 w-4" />
    </div>
  );
}

export function Pagination({
  totalPages,
  pageIndex,
  onPageChange,
  truncate = false,
}: PaginationProps) {
  const start = pageIndex === 0;
  const end = pageIndex === totalPages - 1;

  if (totalPages === 0 || totalPages === Infinity) {
    return null;
  }

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => {
    const pageNumber = i + 1;
    const isActivePage = i === pageIndex;
    return (
      <button
        className={cn(
          'flex cursor-pointer select-none items-center justify-center rounded-md border-2 border-transparent p-2 text-blue-400',
          'hover:bg-blue-700/5 hover:text-blue-700',
          'disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:text-blue-700/20',
          'active:bg-blue-700/10 active:text-blue-700',
          'focus:border-2 focus:border-blue-700 focus:bg-blue-700/5 focus:text-blue-700',
          isActivePage && 'bg-blue-700/5 active:text-blue-800',
        )}
        onClick={() => {
          onPageChange(i);
        }}
        key={`page${pageNumber}`}
      >
        <TextV2
          intent="TextS"
          weight="medium"
          className="min-w-5 text-center tabular-nums"
          key={`page${pageNumber}`}
        >
          {pageNumber}
        </TextV2>
      </button>
    );
  });

  // for truncation
  const isPageAtStart = pageIndex < 4;
  const isPageAtEnd = pageIndex > totalPages - 4;

  const truncatedPages = isPageAtStart
    ? [
        ...pageNumbers.slice(0, 4),
        <WrappedEllipsis key="start-truncate" />,
        pageNumbers[totalPages - 1],
      ]
    : isPageAtEnd
      ? [pageNumbers[0], <WrappedEllipsis key="end-truncate" />, ...pageNumbers.slice(-4)]
      : [
          pageNumbers[0],
          <WrappedEllipsis key="start" />,
          pageNumbers[pageIndex - 1],
          pageNumbers[pageIndex],
          pageNumbers[pageIndex + 1],
          <WrappedEllipsis key="end" />,
          pageNumbers[totalPages - 1],
        ];

  const shouldTruncate = truncate && totalPages > 5;

  return (
    <div className="flex w-full items-center justify-between gap-1 sm:gap-3">
      <button
        disabled={start}
        onClick={() => onPageChange(pageIndex - 1)}
        className={cn(
          'flex items-center justify-center gap-1 rounded-md border-2 border-transparent p-2',
          'text-blue-400 hover:bg-blue-700/5 hover:text-blue-700',
          'disabled:cursor-not-allowed disabled:text-blue-700/20 disabled:hover:bg-transparent disabled:hover:text-blue-700/20',
          'active:bg-blue-700/10 active:text-blue-700',
          'focus:border-2 focus:border-blue-700 focus:bg-blue-700/5 focus:text-blue-700',
        )}
      >
        <ArrowLeftIcon className="h-3 w-3 [&>path]:stroke-[3]" />
        <TextV2 intent="TextS" weight="medium">
          Previous
        </TextV2>
      </button>
      <div className="smg:ap-3 flex items-end justify-between gap-0.5">
        {shouldTruncate ? truncatedPages : pageNumbers}
      </div>
      <button
        disabled={end}
        onClick={() => onPageChange(pageIndex + 1)}
        className={cn(
          'flex items-center justify-center gap-1 rounded-md border-2 border-transparent p-2',
          'text-blue-400 hover:bg-blue-700/5 hover:text-blue-700',
          'disabled:cursor-not-allowed disabled:text-blue-700/20 disabled:hover:bg-transparent disabled:hover:text-blue-700/20',
          'active:bg-blue-700/10 active:text-blue-700',
          'focus:border-2 focus:border-blue-700 focus:bg-blue-700/5 focus:text-blue-700',
        )}
      >
        <TextV2 intent="TextXS" weight="medium">
          Next
        </TextV2>
        <ArrowRightIcon className="h-3 w-3 [&>path]:stroke-[3]" />
      </button>
    </div>
  );
}
