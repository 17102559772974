import { forwardRef } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

const textCva = cva('', {
  variants: {
    intent: {
      Display3XL: ['text-Display3XL'],
      Display2XL: ['text-Display2XL'],
      DisplayXL: ['text-DisplayXL'],
      DisplayL: ['text-DisplayL'],
      DisplayM: ['text-DisplayM'],
      DisplayS: ['text-DisplayS'],
      DisplayXS: ['text-DisplayXS'],
      TextXL: ['text-TextXL'],
      TextL: ['text-TextL'],
      TextM: ['text-TextM'],
      TextS: ['text-TextS'],
      TextXS: ['text-TextXS'],
    },
    font: {
      abcRepro: ['font-abcRepro'],
      ibmPlexMono: ['font-ibmPlexMono'],
    },
    weight: {
      normal: ['font-normal'],
      medium: ['font-medium'],
      semibold: ['font-semibold'],
      bold: ['font-bold'],
    },
  },
  defaultVariants: {
    intent: 'TextM',
    font: 'abcRepro',
    weight: 'normal',
  },
});

export type TextVariants = VariantProps<typeof textCva>;

export interface TextProps extends TextVariants {
  children: React.ReactNode;
  className?: string;
  dataCypress?: string;
  onClick?: () => void;
}

export const TextV2 = forwardRef<HTMLSpanElement, TextProps>(
  ({ children, intent, font, weight, className, dataCypress, onClick, ...props }, ref) => {
    return (
      <span
        data-cypress={dataCypress}
        className={textCva({ intent, font, weight, className })}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </span>
    );
  },
);
