import { Address } from 'abitype';
import {
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';
import { Hash, TransactionReceipt, WriteContractErrorType } from 'viem';
import { Config } from 'wagmi';
import { WriteContractMutate } from 'wagmi/query';

export type token = {
  icon: string;
  name: string;
  symbol: string;
  slug: string; // NOTE: Slug is the conical path to use in the URL for the token, it's either the Address or Symbol
  decimals: number;
  apiID: string | null;
  mainnetAddress?: '' | Address;
  types: Array<'stakeable' | 'reward'>;
  group?: string;
  rebasing: boolean;
  balance?: bigint;
  deposited?: deposits;
  tvl?: number;
  usd?: number;
  address: Address;
  strategyAddress: Address;
  about: string;
  twitter?: string;
  website?: string;
  marketCap?: number;
};

export type LongtailToken = {
  apiId: null | string;
  mainnetAddress?: '' | Address;
  group: string;
  types: Array<'stakeable' | 'reward'>;
  canonical: boolean;
  address: Address;
  name: string;
  symbol: string;
  decimals: number;
  usedForPoints: boolean;
  rebasing: boolean;
  rewards: boolean;
  description: string;
  twitterScreenName: string;
  homepage: string;
  logoUrl: string;
  tvl: string;
  strategy: {
    address: Address;
    exchangeRate: number;
    withdrawalDelayBlocks: number;
    thirdPartyTransfersForbidden: boolean;
  };
};

export type TokenTableData = {
  symbol: string;
  icon: string;
  name: string;
  underlying: bigint;
  balance: string | number | bigint;
  tvl: number;
  slug: string;
  group?: string;
  completableWithdrawalAmount?: bigint;
  pendingWithdrawalAmount?: bigint;
  rewardTokens?: token[];
  onRewardTokenClick?: (symbol: string, rewardTokens: Array<token>) => void;
};

export type AVSInfoType = {
  address: Address;
  metadata: {
    name: string;
    website: string;
    description: string;
    logo: string;
    twitter: string;
  };
  operatorCount: number;
  stakerCount: number;
  strategyAndShare: null;
  totalTVL?: number;
  tvl: Record<string, number>;
};

export type OperatorAVSMetadata = {
  name: string;
  website: string;
  description: string;
  logo: string;
  twitter: string;
};

export type OperatorWithMetadatasResponse = {
  operators: Array<Address>;
  metadata: Array<OperatorAVSMetadata>;
};

export type OperatorWithMetadata = OperatorAVSMetadata & {
  address: Address;
};

export type OperatorStats = {
  address: Address;
  numAvs: number;
  numStakers: number;
  shares: { [strategy: Address]: `${bigint}` };
};

export interface OperatorType extends OperatorWithMetadata, Omit<OperatorStats, 'shares'> {
  totalTVL: number;
  eigenTVL: number;
}

export type deposits = {
  shares: bigint;
  underlying: bigint;
};

export interface TokenPrices {
  [key: string]: {
    usd: number;
    eth: number;
    usd_market_cap: number;
    eth_market_cap: number;
  };
}

/**
 * @name (config) Staking App configuration
 * -------------------------------------------------------
 * @description Configuration object for the restaking app
 * -------------------------------------------------------
 * @property {Address} eigenPodManagerAddress - The address of the EigenPodManager contract
 * @property {token[]} stakingTokenList - A list of staking tokens
 * @property {token} nativeToken - The native token
 * @property {token[]} rewardsTokenList - A list of reward tokens
 * @property {Address} strategyManagerAddress - The address of the StrategyManager contract
 * @property {Address} delayedWithdrawalRouterAddress - The address of the DelayedWithdrawalRouter contract
 * @property {Address} delegationManagerAddress - The address of the DelegationManager contract
 * @property {Address} eigendaServiceManagerAddress - The address of the EigendaServiceManager contract
 * @property {Address?} rewardsCoordinatorAddress - The address of the RewardsCoordinator contract
 */
export interface config {
  eigenPodManagerAddress: Address;
  stakingTokenList: token[];
  nativeToken: token;
  rewardsTokenList: token[];
  strategyManagerAddress: Address;
  delayedWithdrawalRouterAddress: Address;
  delegationManagerAddress: Address;
  eigendaServiceManagerAddress: Address;
  /**
   * @TODO - Make this a required field when it's available
   */
  rewardsCoordinatorAddress: Address | undefined;
}

export interface sharesToUnderlyingRes {
  sharesToUnderlying: bigint;
  strategy: string;
}

type TokenData = {
  icon: string;
  price: number;
  marketCap: number;
  sharesToUnderlyings: sharesToUnderlyingRes[];
  userAddress: `0x${string}` | undefined;
};

export type NativeTokenData = TokenData & {
  globalPodSummary: GlobalPodSummary;
  podOwnerShares: bigint;
};

export type LiquidTokenData = TokenData & {
  ethValue: number;
  tokenSharesStrats: CurrentRestaked;
  tokenTVL: bigint | number;
};

export interface StakingTokenItem extends token {
  setDeposits: (shares: bigint) => deposits;
  setTVL: (tokenTVL: bigint) => void;
  setNativeTVL: (globalPodBalance: bigint) => void;
  setUSD: (price: number) => void;
  initializeNativeToken: ({
    price,
    sharesToUnderlyings,
    userAddress,
    globalPodSummary,
    podOwnerShares,
    marketCap,
  }: NativeTokenData) => void;
  initialize: ({
    price,
    ethValue,
    sharesToUnderlyings,
    userAddress,
    tokenSharesStrats,
    tokenTVL,
    marketCap,
  }: LiquidTokenData) => void;
  convertSharesToUnderlying<
    TFormat extends 'decimal' | 'uint256' = 'uint256',
    TAsString extends boolean | undefined = false,
    TReturnType = TAsString extends true ? string : TFormat extends 'decimal' ? number : bigint,
  >(
    shares: bigint,
    opts?: { format?: TFormat; string?: TAsString },
  ): TReturnType;
  convertUnderlyingToShares<
    TFormat extends 'decimal' | 'uint256',
    TAsString extends boolean,
    TReturnType = TAsString extends true ? string : TFormat extends 'decimal' ? number : bigint,
  >(
    underlying: bigint,
    opts?: { format: TFormat; string: TAsString },
  ): TReturnType;
  formatWithDecimals<
    TAsString extends boolean = false,
    TReturnType = TAsString extends true ? string : number,
  >(
    largeInt: bigint,
    opts?: { string?: TAsString },
  ): TReturnType;
  matchesTokenStrategy: (strategy: Address) => boolean;
}

export interface DashboardStats {
  totalDeposits: number;
  totalStakePoints: number;
  ethPrice: number;
  restakeRatio: number;
}
export type NativeStats = {
  points: number;
  numValidators: number;
  balance: string | bigint;
};

export type StakingTokenList = Array<StakingTokenItem>;

export interface UserConfig {
  stakingTokenList: StakingTokenItem[];
  nativeRestakingToken: StakingTokenItem;
  numEigenPodValidators: number;
}

export interface EigenPodPayment {
  amount: bigint;
  blockCreated: bigint;
}

export interface transaction {
  isLoading: boolean;
  isError: boolean;
  type: string;
  verifyAndProcessWithdrawalsPayload?: VerifyAndProcessWithdrawalCallParams;
  message: string;
  hash?: string;
  amount?: string;
}

export interface TokenInputProps {
  amountAsShares: bigint;
  setAmountAsShares: React.Dispatch<React.SetStateAction<bigint>>;
  onMaxHandler: () => void;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues, undefined>;
  inputId: string;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  reset: UseFormReset<FieldValues>;
}

export type EthereumAddress = Address;

export interface PodSummary {
  eigenPodAddress?: Address;
  inPodNotRestaked: bigint;
  inPodRestaked: bigint;
  inPodUnproven: bigint;
  onBeaconChainUnproven: bigint;
  onBeaconChainRestaked: bigint;
  onBeaconChainNotRestaked: bigint;
  restakingPoints: number;
  latestValidatorActivationTimestamp: bigint;
}

export interface GlobalPodSummary {
  points: number;
  numValidators: number;
  balance: string | bigint;
}

export type withdrawalType = 'withdrawal' | 'queueWithdrawal' | 'nativeQueueWithdrawal';

export interface Withdrawal {
  type: withdrawalType;
  id: string;
  nonce: string;
  withdrawalStartBlock: string;
  withdrawalQueueTxHash: string;
  withdrawalTxHash: string;
  txHash: string;
  status: withdrawalStatus;
  withdrawer: string;
  blockNumber: string;
  shares: string[];
  underlyings: string[];
  strategies: Address[];
}

export interface pendingWithdrawal {
  id: string;
  nonce: string;
  startBlock: bigint;
  shares: bigint[];
  strategies: Address[];
  tokens: Address[] | null;
}

export interface delayedRouterResult {
  delayedWithdrawals: [];
  delayedWithdrawalsCompleted: string;
}

export type nativeUncompletedWithdrawal = {
  amount: bigint;
  blockCreated: bigint;
};

export interface sharesToUnderlyingRes {
  sharesToUnderlying: bigint;
  strategy: string;
}

export interface CurrentRestaked {
  shares: Readonly<bigint[]>;
  strategies: Readonly<Array<Address>>;
}

export interface signatureObj {
  pubKeyHex: Address;
  messageHash: Address;
}

export interface tvlObj {
  strategy: Address;
  tvl: bigint;
}

export interface tokenTableProps {
  symbol: string;
  name: string;
  restaked: bigint;
  tvl: number;
  src: string;
}

export interface tokenProps {
  token: token;
  isEven: boolean;
}

export type interaction = 'deposit' | 'withdraw';

export interface unstakeMenuProps {
  currentBlock: bigint;
  withdrawalsLoading: boolean;
  nextCompletableWithdrawalBlock: bigint;
  handleCompleteWithdrawal?: () => void;
  handleCompleteWithdrawalToEigenLayer?: () => void;
  completeWithdrawal: WriteContractMutate<Config, unknown>;
  completeWithdrawalToEigenLayer: WriteContractMutate<Config, unknown>;
  pendingWithdrawalAmount: bigint;
  completableWithdrawalAmount: bigint;
  completableNativePaymentsAmount: bigint;
  beaconChainBlockedWithdrawalAmount: bigint;
  isCompleteWithdrawalDisabled?: boolean;
  queueWithdrawalTransactionIsFetching?: boolean;
}

export type ActivelyValidatedService = {
  address: Address;
  metadata: {
    name: string;
    description: string;
    website: string;
    twitter: string;
    logo: string;
  };
  operators?: string[];
  operatorCount?: number;
  rewardTokens?: TokenReward[];
  stakerCount?: number;
  tvlByGroup: {
    [key: string]: number;
  };
  totalTVL: number;
};

export type withdrawalCredentialProof = {
  beaconStateRoot: string;
  stateRootProof: string;
  validatorFieldsProof: string;
};

export type beaconStateRoot = Address;
export type stateRootProof = Address;
export type validatorFieldsProof = Address;

export type WithdrawalCredentialArgs = {
  oracleTimestamp: bigint;
  stateRootProof: {
    proof: stateRootProof; // this is sometimes refered as stateRootProof
    beaconStateRoot: beaconStateRoot;
  };
  validatorIndices: number[];
  validatorFieldsProofs: validatorFieldsProof[];
  validatorFields: [][];
};

export type WithdrawalCredentialsResponse = {
  oracleTimestamp: bigint;
  stateRootProof: {
    stateRootProof: stateRootProof;
    beaconStateRoot: beaconStateRoot;
  };
  validatorFields: [][];
  validatorFieldsProofs: validatorFieldsProof[];
  validatorIndices: number[];
};

export type restakeProofResponse = {
  verifyAndProcessWithdrawalCallParams: VerifyAndProcessWithdrawalCallParams;
  verifyWithdrawalCredentialsCallParams: WithdrawalCredentialsResponse;
};

export type WithdrawalProof = {
  withdrawalProof: Address;
  slotProof: Address;
  executionPayloadProof: Address;
  timestampProof: Address;
  historicalSummaryBlockRootProof: Address;
  blockRootIndex: bigint;
  historicalSummaryIndex: bigint;
  withdrawalIndex: bigint;
  blockRoot: Address;
  slotRoot: Address;
  timestampRoot: Address;
  executionPayloadRoot: Address;
};

export type VerifyAndProcessWithdrawalCallParams = {
  oracleTimestamp: bigint;
  stateRootProof: {
    stateRootProof: Address;
    beaconStateRoot: Address;
  };
  validatorFields: [][];
  validatorFieldsProofs: validatorFieldsProof[];
  withdrawalFields: [][];
  withdrawalProofs: WithdrawalProof[];
};

export type FullWithdrawalProofsRes = {
  bufferWei: bigint;
  restakedWei: bigint;
  verifyAndProcessWithdrawalCallParams: VerifyAndProcessWithdrawalCallParams;
};

export type partialWithdrawalProofsRes = {
  provenWei: bigint;
  verifyAndProcessWithdrawalCallParams: VerifyAndProcessWithdrawalCallParams;
};
export type VerifyAndProcessWithdrawalsArgs = {
  oracleTimestamp: bigint;
  stateRootProof: { beaconStateRoot: Address; proof: Address };
  withdrawalProofs: WithdrawalProof[];
  validatorFieldsProofs: validatorFieldsProof[];
  validatorFields: [][];
  withdrawalFields: [][];
};

export interface uncompletedWithdrawalRes {
  pendingWithdrawals: UncompletedWithdrawal[];
  completeableWithdrawals: UncompletedWithdrawal[];
  beaconChainBlockedWithdrawals: UncompletedWithdrawal[];
  weiToProve: bigint;
  tokenList: token[];
}

export enum UncompletedWithdrawalType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}

export const WITHDRAWAL_STATUS_COMPLETABLE = 'completable' as const;
export const WITHDRAWAL_STATUS_PENDING = 'pending' as const;

export type withdrawalStatus =
  | typeof WITHDRAWAL_STATUS_COMPLETABLE
  | typeof WITHDRAWAL_STATUS_PENDING;

export interface UncompletedCommonWithdrawals {
  pendingWithdrawals: UncompletedWithdrawal[];
  completableWithdrawals: UncompletedWithdrawal[];
  beaconChainBlockedWithdrawals: UncompletedWithdrawal[];
  nextCompletableWithdrawalBlock: bigint;
  completableRedelegation: UncompletedWithdrawal[];
  weiToProve: bigint;
}

export type TokenReward = {
  tokenAddress: Address;
  weiAmount: bigint;
};

export type AVSReward = {
  avsAddress: Address;
  tokens: TokenReward[];
};

export interface EarnerUpcomingRewards {
  blockHeight: bigint;
  rewards: AVSReward[];
}

export type EstimatedOperatorReward = {
  operatorAddress: Address;
  apr: number;
  rewards: TokenReward[];
};

export type EstimatedRewardForStrategyResponse = {
  rewards: EstimatedOperatorReward[];
};

export type lastDelegationTimestamp = {
  blockNumber: number;
  blockTimestamp: number;
};

export type EarnableTokensForStrategy = {
  strategyAddress: Address;
  tokenAddresses: Address[];
};

export type EarnableTokensForStrategyResponse = {
  rewards: EarnableTokensForStrategy[];
};

export type LifetimeEarnedResponse = {
  rewards: TokenReward[];
};

export type RewardAPRsForRestakeAmount = {
  operator: Address;
  apr: number;
  rewards: TokenReward[];
};

export type strategyRewards = {
  strategyAddress: Address;
  rewards: AVSReward[];
};
export interface EarnedTokensForStrategy {
  rewards: strategyRewards[];
}
export interface EarnerAPRForStrategy {
  apr: number;
  rewards: TokenReward[];
  strategyAddress: Address;
}

export interface EarnerAPRForStrategyResponse {
  rewards: EarnerAPRForStrategy[];
}

export type ClaimsEarnerLeaf = {
  earner: Address;
  earnerTokenRoot: Address;
};

export type ClaimsTokenLeaf = {
  token: Address;
  cumulativeEarnings: bigint;
};

export interface ClaimsProof {
  root: string;
  rootIndex: number;
  earnerIndex: number;
  earnerTreeProof: string;
  earnerLeaf: ClaimsEarnerLeaf;
  leafIndices: number[];
  tokenTreeProofs: Address[];
  tokenLeaves: ClaimsTokenLeaf[];
}

export interface ClaimsProofResponse {
  proof: ClaimsProof;
}

export type TokenSelection = {
  name: string;
  address: Address;
  symbol: string;
  icon: string;
  weiAmount: bigint;
};

export const SUBGRAPH_TYPES = [
  'eigenlayer-delegation-raw-events',
  'beacon-oracle',
  'strategy-manager-shares-to-underlying',
  'eigenlayer-delegation-statuses-and-shares',
  'eigen-pod-manager',
  'eigen-pod-manager-raw-events',
  'eigen-pod-raw-events',
] as const;

export enum TransactionStatus {
  NotStarted = 'NotStarted',
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

/**
 * Summary of EigenPod deposits/withdrawals within the context of PEPE
 */
export type PEPEPodSummary = {
  /**
   * The address of the EigenPod, if it exists
   */
  eigenPodAddress: Address | null;
  /**
   * Any funds in validators that have not been proven with WCPs
   */
  onBeaconChainUnproven: bigint;
  /**
   * @deprecated Deposits in validators that have been proven with WCPs
   */
  onBeaconChainRestaked: bigint;
  /**
   * @deprecated Rewards that have not yet been swept to the EigenPod from validators
   * that have been proven with WCPs
   */
  onBeaconChainNotRestaked: bigint;
  /**
   * @deprecated Funds in the EigenPod that are immediately withdrawable
   */
  inPodRestakedWithdrawable: bigint;
  /**
   * @deprecated Funds in the EigenPod that are withdrawable after a checkpoint is proven
   */
  inPodRestakedNotWithdrawable: bigint;
  /**
   * @deprecated Funds in the EigenPod that have not been proven with CPPs
   */
  inPodUnproven: bigint;
  /**
   * @deprecated Restaking points the user has earned
   */
  restakingPoints: bigint;
  /**
   * Funds in pod from a validator exit
   */
  inPodFullWithdrawal: bigint;
  /**
   * Total shares that the pod has
   */
  totalRestakedBalance: bigint;
  /**
   * Unstaked balance in pod and on beacon chain
   */
  unrestakedBalance: bigint;
  /**
   * Total balance in the pod
   */
  withdrawableBalance: bigint;
  /**
   * Total balance withdrawable after a checkpoint
   */
  withdrawableNow: bigint;
  /**
   * Total balance withdrawable that requires a checkpoint (exclusive of withdrawableNow)
   */
  withdrawableAfterCheckpoint: bigint;
  /**
   * That aggregate total of all incomplete delegation withdrawals
   */
  totalUndelegatedWithdrawals: bigint;
  /**
   * That aggregate total of all incomplete withdrawals that aren't undelgations
   */
  totalOtherWithdrawals: bigint;
  /**
   * The actual amount of funds that can be restaked with a checkpoint
   */
  totalRestakableBalance: bigint;
};

/**
 * A validator that has its withdrawal credentials pointed at the EigenPod
 */
export type PodValidatorSummary = {
  /**
   * Number of nonzero-balance validators that have been proven with
   * withdrawal credential proofs
   */
  provenCount: number;
  /**
   * Number of nonzero-balance validators that need to be proven with
   * withdrawal credential proofs
   */
  unprovenCount: number;
};

export type BalanceProof = {
  pubkeyHash: Hash;
  balanceRoot: Hash;
  proof: Hash;
};

export type BalanceContainerProof = {
  balanceContainerRoot: Hash;
  proof: Hash;
};

export type CheckpointProofs = {
  eigenPodAddress: Address;
  balanceContainerProof: BalanceContainerProof;
  balanceProofs: BalanceProof[];
};

export type ProofsServiceBalanceContainerProofRes = {
  validatorBalancesRoot: Hash;
  proof: Hash;
};

export type ProofsServiceBalanceProofRes = {
  pubKeyHash: Hash;
  balanceRoot: Hash;
  proof: Hash;
};

export type ParsedCheckpointProofRes = {
  validatorBalancesRootProof?: ProofsServiceBalanceContainerProofRes;
  balanceProofs: ProofsServiceBalanceProofRes[];
};

export type Base64String = string;
export type HexString = string;

export type ProofServiceBytesContainer = { FieldsAsHex: HexString[] };

export type EncodedStateRootProof = {
  beaconStateRoot: Base64String;
  proof: Base64String;
};

export enum OperationStatus {
  OPERATION_STATUS_TRY_AGAIN = 'OPERATION_STATUS_TRY_AGAIN',
  OPERATION_STATUS_FAILURE = 'OPERATION_STATUS_FAILURE',
  OPERATION_STATUS_SUCCESS = 'OPERATION_STATUS_SUCCESS',
}

export type ParsedWithdrawalCredentialProofRes = {
  status: OperationStatus;
  oracleBeaconTimestamp: `${number}`;
  proofs: {
    stateRootProof: EncodedStateRootProof;
    validatorIndices: Array<`${number}`>;
    validatorFieldProofs: Base64String[];
    validatorFields: ProofServiceBytesContainer[];
  };
};

export type WithdrawalCredentialProof = {
  validatorIndex: number;
  validatorFieldsProof: Hash;
  validatorFields: Hash[];
};

export type StateRootProof = {
  beaconStateRoot: Hash;
  proof: Hash;
};

export type WithdrawalCredentialProofs<T extends `${bigint}` | bigint = bigint> = {
  eigenPodAddress: Address;
  beaconTimestamp: T;
  stateRootProof: StateRootProof;
  withdrawalCredentialProofs: WithdrawalCredentialProof[];
};

export type UncompletedWithdrawal = {
  /**
   * The staker address that queued the withdrawal
   */
  staker: Address;
  /**
   * Will always be the staker on the UI. It is possible to set a
   *  different withdrawer address.
   */
  withdrawer: Address;
  /**
   * The operator address the staker is delegated to.
   */
  delegatedTo: Address;
  /**
   * True when the withdrawal was queued by an undelegate
   * transaction and the user's beaconchainRestaked balance > 0
   */
  isUndelegationQueue: boolean;
  /**
   * Withdrawal nonce for the staker
   */
  nonce: bigint;
  /**
   * A list of shares for each respective strategy in the withdrawal
   */
  shares: bigint[];
  /**
   * A list of all the strategy addresses in the withdrawal.
   */
  strategies: Address[];
  /**
   * The block the withdrawal was queued.
   */
  startBlock: number;
  /**
   * The block the withdrawal was queued.
   */
  completableBlock: number;
  /**
   * The state root of the withdrawal
   */
  withdrawalRoot: Hash;
};

export type UncompletedWithdrawalsResponse = {
  /**
   * uncompleted withdrawals with a
   * startBlock + withdrawDelayBlocks > currentBlock
   */
  pendingWithdrawals: UncompletedWithdrawal[];
  /**
   * uncompleted withdrawals with a
   * startBlock + withdrawDelayBlocks <= currentBlock
   */
  completeableWithdrawals: UncompletedWithdrawal[];
};

export interface UncompletedWithdrawalsCommon {
  /**
   * uncompleted withdrawals with a
   * startBlock + withdrawDelayBlocks > currentBlock
   */
  pendingWithdrawals: UncompletedWithdrawal[];
  /**
   * uncompleted withdrawals with a
   * startBlock + withdrawDelayBlocks <= currentBlock
   */
  completableWithdrawals: UncompletedWithdrawal[];
  /**
   * uncompleted withdrawals that can be redelegated
   */
  completableRedelegation: UncompletedWithdrawal[];
  /**
   * next block that a withdrawal can be completed
   */
  nextCompletableWithdrawalBlock: bigint;
}

export interface UncompletedWithdrawals extends UncompletedWithdrawalsCommon {
  type: UncompletedWithdrawalType.SINGLE;
  strategyAddress?: Address;
  pendingWithdrawalAmount: bigint;
  completableWithdrawalAmount: bigint;
}

export interface UncompletedMultiAssetWithdrawals extends UncompletedWithdrawalsCommon {
  type: UncompletedWithdrawalType.MULTI;
  symbols: string;
  pendingWithdrawalAmounts: bigint[];
  completableWithdrawalAmounts: bigint[];
  strategyAddresses: Address[];
}

export interface UncompletedMultiAssetWithdrawalsWithToken
  extends UncompletedMultiAssetWithdrawals {
  tokens: token[];
}

export interface UncompletedWithdrawalsWithToken
  extends Omit<UncompletedWithdrawals, 'strategyAddress'>,
    token {}

export interface UncompletedWithdrawalsBySymbol {
  [symbol: string]: UncompletedWithdrawalsWithToken;
}

export type UncompletedWithdrawalsReturn = {
  block?: bigint;
} & UncompletedWithdrawalsWithToken;

export type StoreTransaction = {
  status: TransactionStatus;
  blockNumber: bigint | null;
  hash: Address | null;
};

export interface TransactionLifecycle {
  onTxnSuccess?: (txnReceipt?: TransactionReceipt) => void;
  onTxnRevert?: (txnReceipt?: TransactionReceipt) => void;
  onTxnSettled?: (txnReceipt?: TransactionReceipt) => void;
  onTxnError?: () => void;
  onError?: (error?: WriteContractErrorType) => void;
  onSuccess?: (hash?: Address) => void;
  onSettled?: () => void;
  onMutate?: () => void;
  updateTransaction?: (updatedTxn: Partial<StoreTransaction>) => void;
}

export interface TransactionModalOnchainTxn extends StoreTransaction {
  label: React.ReactNode;
  subLabel?: React.ReactNode;
  write: () => void;
}

export type TransactionModalAsyncFunction = Omit<
  TransactionModalOnchainTxn,
  'blockNumber' | 'hash'
>;

export type TransactionModalTxn = TransactionModalAsyncFunction | TransactionModalOnchainTxn;

export type Web3ConfigMetadata = {
  name: string;
  description: string;
  url: string;
  icons: string[];
};

export type OperatorMetadata = {
  name: string;
  website: string;
  description: string;
  logo: string;
  twitter: string;
};

export type PaginationQueryParams<
  T = Record<string, unknown>,
  TSortBy = KeysOfType<T, string | bigint | number>,
  TSortDirection = TSortBy extends never ? never : 'ASC' | 'DESC',
> = {
  // ?first=X
  first?: number;
  // ?skip=Y
  skip?: number;
  // ?sortBy=Z
  sortBy?: TSortBy;
  // ?sortDirection=ASC|DESC
  sortDirection?: TSortDirection;
};

export type OpSetFilters = {
  staker: Address;
  token: Address;
  operator: Address;
  opSetId: bigint;
  avs: Address;
};

export type NeverOpSetFilters = { [K in keyof OpSetFilters]?: never };

export type TokenMetadata = {
  name: string;
  address: Address;
  symbol: string;
  decimals: number;
  logo: string;
};

export type HistoricalAPR = {
  tokens: TokenMetadata[];
  apr: number;
};

export type HistoricalAggregateAPR = {
  tokens: TokenMetadata[];
  aprMin: number;
  aprMax: number;
};

export type TokenRewardWithEthValue = {
  metadata: TokenMetadata;
  amount: bigint;
  ethValue: bigint;
};

export type RestakedDepositSummary = {
  /**
   * Total of all deposited funds
   */
  delegated: bigint;
  /**
   * Slashabled funds that are allocated and NOT queued for withdrawal
   */
  active: bigint;
  /**
   * Non-Slashable funds that are delegated but the operator does not utilize
   * (e.g. tokens that are delegated but not allocated to an operator sets, etc.)
   * @note Not in current designs but expected to be
   */
  inactive: bigint;
};

export type StakerStateSummary = {
  /**
   * Deposits for the user. All values are denominated in ETH.
   */
  deposits: RestakedDepositSummary;
  claims: {
    /**
     * Total ETH-denominated rewards that can been claimed
     */
    claimableEthValue: bigint;
    /**
     * Historical annualized reward percentage along with a list of tokens
     */
    historicalApr: HistoricalAPR;
  };
};

export type OperatorToken = {
  operator: OperatorMetadata & {
    address: Address;
  };
  metadata: TokenMetadata;
  strategyAddress: Address;
  amount: bigint;
  slashingAllocationPercent: number;
  weeklyRewards: HistoricalAPR;
};

export type OperatorStatsV2 = {
  address: Address;
  metadata: OperatorMetadata;
  tvlEthValue: bigint;
  stakerCount: number;
  slashesCount: number;
  historicalAPR: HistoricalAPR;
};

export type AggregatedOpSetAVSStats = {
  avs: Address;
  metadata: OperatorAVSMetadata;
  restakedEthValue: bigint;
  stakerCount: number;
  operatorCount: number;
  historicalAggregateAPR: HistoricalAggregateAPR;
};

export type OpSetStats = RestakedDepositSummary & {
  operatorSetId: bigint;
  avs: Address;
  metadata: OpSetMetadata | null;
  stakerCount: number;
  operatorCount: number;
  historicalAPR: HistoricalAPR;
  weeklyRewards: TokenRewardWithEthValue[];
};

export type OpSetMetadata = {
  name: string;
  id: bigint;
  description: string;
  software: {
    name: string;
    description: string;
    url: string;
  }[];
  strategies: Address[];
  slashingConditions: string[];
};

export type OpSetOperator = {
  operatorSetId: bigint;
  address: Address;
  metadata: OperatorMetadata;
  stakerCount: number;
  avsCount: number;
  tvlEthValue: bigint;
  opSetTvlEthValue: bigint;
  historicalApr: HistoricalAPR;
};

export type SlashableRestakedToken = RestakedDepositSummary & {
  /**
   * Metadata for the token
   */
  metadata: TokenMetadata;
  /**
   * The address of the token's in-protocol strategy
   */
  strategyAddress: Address;
  /**
   * ETH value of the slashable tokens
   */
  exchangeRates: {
    usd: number;
    eth: number;
  };
  /**
   * Historical annualized reward percentage along with a list of tokens
   */
  historicalRewards: HistoricalAPR;
  /**
   * @M1 Unused. Can be `0n`
   * @M2 Will be the actual value
   */
  slashablePercent: bigint;
};

export type OpSetAllocations = {
  operatorSetId: bigint;
  avs: Address;
  totalEthValue: bigint;
  historicalApr: HistoricalAPR;
  allocations: Array<{
    tokens: TokenRewardWithEthValue[];
    metadata: TokenMetadata;
    strategyAddress: Address;
    amount: bigint;
    ethValue: bigint;
    pendingUpdates: {
      timestamp: number;
      percent: number;
      amount: bigint;
    };
  }>;
};

export type SlashingEvent = {
  operatorSetId: bigint;
  operator: Address;
  avs: Address;
  timestamp: number;
  slashedPercent: number;
  txHash: Hash;
  reason: string | null;
  tokens: Array<{
    metadata: TokenMetadata;
    strategyAddress: Address;
    amountPrevious: bigint;
    amountSlashed: bigint;
  }>;
};

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type AllOrNothing<T extends Record<string, unknown>> = T | Partial<Record<keyof T, never>>;

export type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

export const createQueryString = (params: Record<string, unknown>) => {
  const queryParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries(params)
        .filter(([, v]) => v != null)
        .map(([k, v]) => [k, `${v}`]),
    ),
  );
  return queryParams ? `?${queryParams}` : ' ';
};
