'use client';

import { SVGProps, useState } from 'react';

import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { Pie } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';

import { colors } from '@monorepo/tailwind-config';

import { TextV2 } from '../../foundations/V2/Text';

const DEFAULT_COLORS = [
  colors.lavender[50],
  colors.blue[50],
  colors.emerald[50],
  colors.mustard[50],
  colors.manilla[50],
  colors.white[950],
];

interface PieDatum {
  label: string;
  value: number;
  borderColor?: string;
  backgroundColor?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface MarginConfig {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

const defaultMargins: MarginConfig = {
  top: 16,
  right: 16,
  bottom: 16,
  left: 16,
};

export interface TvlPieChartProps {
  data: PieDatum[];
  thickness?: number;
  cornerRadius?: number;
  padAngle?: number;
  label?: string | number;
  subLabel?: string;
  margins?: MarginConfig;
  withLabels?: boolean;
  labelSize?: number;
  labelProps?: Partial<Omit<SVGProps<SVGTextElement>, 'x' | 'y' | 'dy'>>;
  defaultColors?: Array<string>;
  minLabelArcLength?: number;
}

export function TvlPieChart({
  data,
  label,
  subLabel,
  thickness = 28,
  cornerRadius = 6,
  padAngle = 0.03,
  margins = defaultMargins,
  withLabels = true,
  labelSize = 14,
  labelProps = {},
  defaultColors = DEFAULT_COLORS,
  minLabelArcLength = 0.1,
}: TvlPieChartProps) {
  const [activeItem, setActiveItem] = useState<PieDatum | null>(null);
  const { left, right, top, bottom } = margins;
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<PieDatum>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  return (
    <ParentSize>
      {({ width, height }) => {
        const innerWidth = width - left - right;
        const innerHeight = height - top - bottom;
        const radius = Math.min(innerWidth, innerHeight) / 2;
        const centerY = innerHeight / 2;
        const centerX = innerWidth / 2;

        if (width === 0 || height === 0) {
          return null;
        }
        return (
          <svg width={width} height={height} className="font-ibmPlexMono" ref={containerRef}>
            <Group top={centerY + top} left={centerX + left}>
              <Pie
                data={data}
                outerRadius={radius}
                innerRadius={({ data }) => {
                  const isActive = activeItem?.label === data.label;
                  const width = radius - thickness;
                  return isActive ? width - 2 : width;
                }}
                cornerRadius={cornerRadius}
                padAngle={padAngle}
                pieValue={(d) => d.value}
              >
                {(pie) => {
                  return pie.arcs.map((arc, index) => {
                    const arcData = arc.data;
                    const { label, onMouseEnter, onMouseLeave, backgroundColor, borderColor } =
                      arcData;
                    const isActive = activeItem?.label === label;
                    const backupColor = defaultColors[index % defaultColors.length];
                    const [centroidX, centroidY] = pie.path.centroid(arc);
                    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= minLabelArcLength;
                    return (
                      <g
                        key={label}
                        onMouseMove={(event) => {
                          // TooltipInPortal expects coordinates to be relative to containerRef
                          // localPoint returns coordinates relative to the nearest SVG, which
                          // is what containerRef is set to in this example.
                          const pointInSpace = localPoint(event);
                          showTooltip({
                            tooltipData: arcData,
                            tooltipLeft: pointInSpace?.x,
                            tooltipTop: pointInSpace?.y,
                          });
                        }}
                        onMouseEnter={() => {
                          setActiveItem(arcData);
                          onMouseEnter?.();
                        }}
                        onMouseLeave={() => {
                          hideTooltip();
                          setActiveItem(null);
                          onMouseLeave?.();
                        }}
                        className="cursor-pointer"
                      >
                        <path
                          d={pie.path(arc) as string}
                          fill={backgroundColor || backupColor}
                          stroke={borderColor || backupColor}
                          strokeWidth={isActive ? 2 : 1}
                        />
                        {hasSpaceForLabel && withLabels && (
                          <g>
                            <text
                              fill="black"
                              x={centroidX}
                              y={centroidY}
                              dy=".33em"
                              fontSize={10}
                              textAnchor="middle"
                              pointerEvents="none"
                              {...labelProps}
                            >
                              {label}
                            </text>
                          </g>
                        )}
                      </g>
                    );
                  });
                }}
              </Pie>
              {label && (
                <text
                  className="fill-blue-700 font-abcRepro tabular-nums"
                  textAnchor="middle"
                  fontSize={labelSize}
                >
                  {label}
                </text>
              )}
              {subLabel && (
                <text
                  className="fill-blue-700 font-abcRepro"
                  textAnchor="middle"
                  y={18}
                  fontSize={12}
                >
                  {subLabel}
                </text>
              )}
            </Group>
            {tooltipOpen && tooltipData && (
              <TooltipInPortal
                top={tooltipTop}
                left={tooltipLeft}
                className="align-center bg-interactivePrimary text-accentPrimaryFg flex min-h-max flex-col items-center rounded p-2"
              >
                <TextV2 intent="TextXL" font="ibmPlexMono">
                  {tooltipData?.label}
                </TextV2>
              </TooltipInPortal>
            )}
          </svg>
        );
      }}
    </ParentSize>
  );
}
