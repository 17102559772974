import { Address } from 'viem';

import { cn } from '@/core/util';

import { TextV2 } from '../../foundations/V2/Text';
import { ComponentSize } from '../../types';
import { Button as ButtonV2 } from '../V2/Button';
import { AddressDropdown } from './AddressDropdown';

interface ConnectWalletProps {
  open: () => void;
  disconnect: () => void;
  isConnected: boolean;
  address?: Address;
  ensName?: string | null;
  blockExplorerURL: string;
  intent?: 'primary' | 'secondary';
  className?: string;
  disconnectedClassName?: string;
  connectedClassName?: string;
}

export function ConnectWallet({
  open,
  disconnect,
  isConnected,
  address,
  ensName,
  blockExplorerURL,
  intent = 'secondary',
  className,
  disconnectedClassName,
  connectedClassName,
}: ConnectWalletProps) {
  return (
    <div className={className}>
      {isConnected && address ? (
        <>
          {/* For Desktop */}
          <AddressDropdown
            address={address}
            disconnect={disconnect}
            ensName={ensName as string | undefined}
            className={connectedClassName}
            blockExplorerURL={blockExplorerURL}
          />
          {/* For Mobile*/}
        </>
      ) : (
        <ButtonV2
          intent={intent}
          className={cn('text-LabelS w-full text-nowrap font-abcRepro', disconnectedClassName)}
          size={ComponentSize.SM}
          dataCypress="connectWallet"
          onClick={() => open()}
        >
          <TextV2 intent="TextM" weight="medium" className="normal-case">
            Connect wallet
          </TextV2>
        </ButtonV2>
      )}
    </div>
  );
}

export default ConnectWallet;
