export function EthIcon({ className }: { className?: string }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 149.5 247.95"
      width="12"
      height="12"
      className={className}
    >
      <path
        d="m74.75,1.5l73.25,125.12-73.53,45.43L1.5,126.25,74.75,1.5Zm.09,244.95l73.08-105.08-73.08,45.78L1.59,141.64l73.25,104.81Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10"
      />
    </svg>
  );
}

export default EthIcon;
