import * as Popover from '@radix-ui/react-popover';
import { cva, VariantProps } from 'class-variance-authority';

import { ComponentSize } from '../../types';

const infoPopoverCva = cva(
  'flex text-[10px] font-abcRepro items-center justify-center cursor-pointer hover:text-interactivePrimaryHover hover:border-interactivePrimaryHover outline-none border-t-[1.5px] border-r-[1.5px] border-b-[1.5px] border-l-[1.5px] rounded-full p-1.5',
  {
    variants: {
      size: {
        [ComponentSize.MD]: 'w-4 h-4',
        [ComponentSize.LG]: 'w-6 h-6',
      },
    },
    defaultVariants: {
      size: ComponentSize.MD,
    },
  },
);

type InfoPopoverVariants = VariantProps<typeof infoPopoverCva>;

const contentCva = cva(
  'bg-blue-100 p-2 rounded flex items-center align-center outline-none text-TextXS',
);

export interface InfoPopoverProps extends InfoPopoverVariants {
  triggerClassName?: string;
  contentClassName?: string;
  trigger?: React.ReactNode;
  children: React.ReactNode;
}

export function InfoPopover({
  size,
  triggerClassName,
  contentClassName,
  trigger,
  children,
}: InfoPopoverProps) {
  return (
    <Popover.Root>
      <Popover.Trigger>
        {trigger ? (
          trigger
        ) : (
          <div className={infoPopoverCva({ className: triggerClassName, size })}>i</div>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content side="top" sideOffset={2}>
          <Popover.Arrow className="fill-interactivePrimary" />
          <div className={contentCva({ className: contentClassName })}>{children}</div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
