import { createContext, ReactNode } from 'react';

import toast, { LoaderIcon, Toaster, ToastOptions } from 'react-hot-toast';

import Toast from './Component';

export type ToastHandler = (
  message: JSX.Element | string | null,
  options?: ToastOptions,
  disableClose?: boolean,
) => string;

export type ToastContextType = {
  info: ToastHandler;
  custom: ToastHandler;
  danger: ToastHandler;
  warning: ToastHandler;
  success: ToastHandler;
  loading: ToastHandler;
  asyncPromise: <T>(
    promise: Promise<T>,
    messages: {
      loading: JSX.Element | string | null;
      success: JSX.Element | string | null;
      error: JSX.Element | string | null;
    },
    options?: ToastOptions,
  ) => Promise<T>;
  dismiss: (toastId?: string) => void;
};

export const ToastContext = createContext<ToastContextType>(
  undefined as unknown as ToastContextType,
);

const custom: ToastHandler = (message, options) => {
  return toast.custom(
    (t) => <Toast type={null} dismiss={() => dismiss(t.id)} message={message} />,
    { ...options, duration: options?.duration ?? 5000 },
  );
};

const success: ToastHandler = (message, options) => {
  return toast.custom(
    (t) => <Toast type="success" dismiss={() => dismiss(t.id)} message={message} />,
    { ...options, duration: options?.duration ?? 5000 },
  );
};

const warning: ToastHandler = (message, options) => {
  return toast.custom(
    (t) => <Toast type="warning" dismiss={() => dismiss(t.id)} message={message} />,
    { ...options, duration: options?.duration ?? 5000 },
  );
};

const danger: ToastHandler = (message, options) => {
  return toast.custom(
    (t) => <Toast type="danger" dismiss={() => dismiss(t.id)} message={message} />,
    { ...options, duration: options?.duration ?? 5000 },
  );
};

const info: ToastHandler = (message, options) => {
  return toast.custom(
    (t) => <Toast type="info" dismiss={() => dismiss(t.id)} message={message} />,
    { ...options, duration: options?.duration ?? 5000 },
  );
};

const loading: ToastHandler = (message, options, disableClose = true) => {
  return toast.custom(
    (t) => (
      <Toast
        type="info"
        dismiss={() => dismiss(t.id)}
        message={message}
        icon={<LoaderIcon />}
        disableClose={disableClose}
      />
    ),
    {
      ...options,
      duration: 999999,
    },
  );
};

const asyncPromise = function <T>(
  promise: Promise<T>,
  messages: {
    loading: JSX.Element | string | null;
    success: JSX.Element | string | null;
    error: JSX.Element | string | null;
  },
  options?: ToastOptions,
) {
  const id = loading(messages.loading, options);

  promise
    .then((p) => {
      success(messages.success, {
        duration: 5000,
        id: id,
        ...options,
      });
      return p;
    })
    .catch((e) => {
      danger(messages.error, {
        duration: 5000,
        id: id,
        ...options,
      });
      throw e;
    });

  return promise;
};

const dismiss = (toastId?: string) => {
  toast.remove(toastId);
};

export function ToastProvider({ children }: { children: ReactNode }) {
  return (
    <ToastContext.Provider
      value={{
        custom,
        success,
        danger,
        warning,
        loading,
        dismiss,
        info,
        asyncPromise,
      }}
    >
      <Toaster
        toastOptions={{
          position: 'bottom-right',
          duration: 5000,
        }}
      />
      {children}
    </ToastContext.Provider>
  );
}

export default ToastProvider;
