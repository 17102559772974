import {
  ChevronDoubleRightIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';

import { TextV2 } from '../../foundations/V2/Text';

export interface PaginationProps {
  totalPages: number;
  pageIndex: number;
  onPageChange: (page: number) => void;
  truncate?: boolean;
}

function WrappedEllipsis() {
  return (
    <div className="flex h-4 w-4 items-center justify-center">
      <EllipsisHorizontalIcon className="h-4 w-4" />
    </div>
  );
}

export function Pagination({
  totalPages,
  pageIndex,
  onPageChange,
  truncate = false,
}: PaginationProps) {
  const isAtStart = pageIndex === 0;
  const isAtEnd = pageIndex === totalPages - 1;

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => {
    const pageNumber = i + 1;
    const isActivePage = i === pageIndex;
    return (
      <div
        className={`flex h-9 w-9 select-none items-center justify-center px-4 py-2 ${
          isActivePage
            ? 'bg-interactiveTertiaryActive'
            : 'hover:bg-interactiveQuarternaryHover cursor-pointer'
        }`}
        onClick={() => {
          onPageChange(i);
        }}
        key={`page${pageNumber}`}
      >
        <TextV2 intent="TextS" font="ibmPlexMono" key={`page${pageNumber}`}>
          {pageNumber}
        </TextV2>
      </div>
    );
  });

  // for truncation
  const isPageAtStart = pageIndex < 4;
  const isPageAtEnd = pageIndex > totalPages - 4;

  const truncatedPages = isPageAtStart
    ? [
        ...pageNumbers.slice(0, 4),
        <WrappedEllipsis key="start-truncate" />,
        pageNumbers[totalPages - 1],
      ]
    : isPageAtEnd
      ? [pageNumbers[0], <WrappedEllipsis key="end-truncate" />, ...pageNumbers.slice(-4)]
      : [
          pageNumbers[0],
          <WrappedEllipsis key="start" />,
          pageNumbers[pageIndex - 1],
          pageNumbers[pageIndex],
          pageNumbers[pageIndex + 1],
          <WrappedEllipsis key="end" />,
          pageNumbers[totalPages - 1],
        ];

  const handleChevronClick = (direction: 'left' | 'right') => {
    if (direction === 'left' && !isAtStart) {
      onPageChange(pageIndex - 1);
    } else if (direction === 'right' && !isAtEnd) {
      onPageChange(pageIndex + 1);
    }
  };

  const chevronClass = 'h-7 w-7 p-1 flex items-center justify-center';
  const leftChevronClass = `rotate-180 ${chevronClass} ${
    isAtStart
      ? 'text-disabledFg'
      : 'cursor-pointer text-blue-700 hover:bg-interactiveQuarternaryHover'
  }`;
  const rightChevronClass = `${chevronClass} ${
    isAtEnd
      ? 'text-disabledFg'
      : 'cursor-pointer text-blue-700 hover:bg-interactiveQuarternaryHover'
  }`;

  const shouldTruncate = truncate && totalPages > 5;

  return (
    <div className="flex items-center gap-1 sm:gap-3">
      {shouldTruncate && (
        <div
          className={leftChevronClass}
          onClick={() => {
            if (!isAtStart) onPageChange(0);
          }}
        >
          <ChevronDoubleRightIcon className="h-7 w-7" />
        </div>
      )}
      <div className={leftChevronClass} onClick={() => handleChevronClick('left')}>
        <ChevronRightIcon className="h-7 w-7" />
      </div>
      {shouldTruncate ? truncatedPages : pageNumbers}
      <div className={rightChevronClass} onClick={() => handleChevronClick('right')}>
        <ChevronRightIcon className="h-7 w-7" />
      </div>
      {shouldTruncate && (
        <div
          className={rightChevronClass}
          onClick={() => {
            if (!isAtEnd) onPageChange(totalPages - 1);
          }}
        >
          <ChevronDoubleRightIcon className="h-7 w-7" />
        </div>
      )}
    </div>
  );
}
