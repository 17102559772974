import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/core/util';

import { TextV2, TextVariants } from '../../foundations/V2/Text';

const valueLoaderCva = cva('bg-lightblue-500 rounded-md text-transparent transform-gpu', {
  variants: {
    animate: {
      true: 'animate-pulse',
      false: '',
    },
  },
  defaultVariants: {
    animate: true,
  },
});
export type LoaderVariants = VariantProps<typeof valueLoaderCva>;

export interface ValueLoaderProps extends TextVariants, LoaderVariants {
  className?: string;
  animate?: boolean;
  placeholder?: string;
}

export function ValueLoader({
  className,
  animate,
  intent,
  placeholder = 'Value',
}: ValueLoaderProps) {
  return (
    <div className={cn(valueLoaderCva({ className, animate }))}>
      {intent ? <TextV2 intent={intent}>{placeholder}</TextV2> : placeholder}
    </div>
  );
}
