import { Address } from 'viem';

import { TextV2 } from '../../foundations/V2/Text';
import { EtherscanLogo } from '../../Icons/EtherscanLogo';

export function TransactionLink({
  hash,
  blockExplorerURL,
}: {
  hash: Address | null;
  blockExplorerURL: string;
}) {
  return (
    <a
      className={`flex gap-1.5 ${
        hash ? 'cursor-pointer hover:underline' : 'pointer-events-none text-blue-400'
      }`}
      href={`${blockExplorerURL}/tx/${hash ?? ''}`}
      target="_blank"
      rel="noreferrer"
    >
      <EtherscanLogo className="h-4 w-4" />
      <TextV2 intent="TextXS" className="after:content-['_↗']">
        View transaction
      </TextV2>
    </a>
  );
}
