import { ReactNode } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { cva, VariantProps } from 'class-variance-authority';
import { Renderable } from 'react-hot-toast';

const notificationToastCva = cva(
  'relative border rounded-md py-4 px-5 transition data-[swipe=move]:translate-x-[--radix-toast-swipe-move-x] data-[swipe=cancel]:translate-x-0 data-[swipe=end]:animation-[toastSlideRight_100ms_ease-out] z-[60]',
  {
    variants: {
      type: {
        success: ['bg-emerald-50 border-borderSuccess text-emerald-500'],
        danger: ['bg-red-100 border-borderDanger text-dangerPrimaryFg'],
        warning: ['bg-mustard-100 border-borderWarning text-yellow-600'],
        info: ['bg-white-950 border-borderInfo text-accentSecondaryFg'],
      },
    },
    defaultVariants: {
      type: 'info',
    },
  },
);

type NotificationVariants = VariantProps<typeof notificationToastCva>;

type ToastProp = {
  message: ReactNode;
  className?: string;
  icon?: Renderable;
  disableClose?: boolean;
  dismiss: () => void;
} & NotificationVariants;

export default function Toast({
  type,
  className,
  dismiss,
  message,
  icon,
  disableClose = false,
}: ToastProp) {
  return (
    <div className={notificationToastCva({ type, className })}>
      <div className="flex items-center gap-2">
        {icon}
        {message}
      </div>
      {disableClose ? null : (
        <XMarkIcon
          className="center absolute right-2 top-2 h-4 w-4 cursor-pointer hover:brightness-125"
          onClick={() => dismiss()}
        />
      )}
    </div>
  );
}
