export function XLogo({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <path
        d="M7.22755 10.1013L2.93705 15.0003H0.560547L6.11755 8.65234L7.22755 10.1013Z"
        fill="currentColor"
      />
      <path d="M8.52539 5.494L12.4539 1H14.8289L9.62539 6.951L8.52539 5.494Z" fill="currentColor" />
      <path
        d="M15.7439 15H10.9644L0.255859 1H5.15636L15.7439 15ZM11.6199 13.5785H12.9359L4.44136 2.347H3.02936L11.6199 13.5785Z"
        fill="currentColor"
      />
    </svg>
  );
}
