import { isValidElement } from 'react';

import { cva } from 'class-variance-authority';

import { cn } from '@/core/util';

import { TextV2 } from '../../foundations/V2/Text';
import { ValueLoader } from '../ValueLoader';

export interface StatCardProps {
  stat: React.ReactNode;
  label: React.ReactNode;
  className?: string;
  intent?: 'primary' | 'secondary';
  isLoading?: boolean;
  loaderClassName?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const statCardCva = cva('flex flex-col whitespace-nowrap', {
  variants: {
    intent: {
      primary: ['lg:flex-row lg:items-end bg-white-950'],
      secondary: ['bg-secondaryBg px-4 py-6 sm:px-6 lg:px-12 sm:py-8 lg:py-12 rounded-lg'],
    },
  },
});

export function StatCard({
  stat,
  label,
  className,
  intent,
  isLoading,
  loaderClassName,
  onClick,
}: StatCardProps) {
  return (
    <div className={cn(statCardCva({ intent }), className)} onClick={onClick && onClick}>
      {intent === 'primary' ? (
        <>
          {isLoading ? (
            <ValueLoader
              intent="DisplayM"
              className={`mb-4 mr-0 text-DisplayM sm:text-DisplayL md:text-DisplayXL lg:mb-0 lg:mr-4 lg:text-Display2XL ${loaderClassName}`}
            />
          ) : isValidElement(stat) ? (
            stat
          ) : (
            <TextV2
              intent="DisplayM"
              className="mb-4 mr-0 text-DisplayM sm:text-DisplayL md:text-DisplayXL lg:mb-0 lg:mr-4 lg:text-Display2XL"
            >
              {stat}
            </TextV2>
          )}
          {label && (
            <TextV2
              intent="DisplayXS"
              className="lg:textDisplayL truncate text-DisplayXS sm:text-DisplayS md:text-DisplayM"
            >
              {label}
            </TextV2>
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <ValueLoader
              intent="DisplayXS"
              className={`text-Heading3 md:text-Heading2 mb-4 ${loaderClassName}`}
            />
          ) : isValidElement(stat) ? (
            stat
          ) : (
            <TextV2 intent="DisplayXS" className="mb-4 text-DisplayXS md:text-DisplayS">
              {stat}
            </TextV2>
          )}
          {label && (
            <TextV2 intent="TextS" font="ibmPlexMono" className="truncate text-TextS md:text-TextM">
              {label}
            </TextV2>
          )}
        </>
      )}
    </div>
  );
}
