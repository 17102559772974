import { Transport } from 'viem';
import { Config, createConfig, fallback, http } from 'wagmi';
import { holesky, mainnet } from 'wagmi/chains';
import { coinbaseWallet, injected, safe, walletConnect } from 'wagmi/connectors';

import { Web3ConfigMetadata } from '@/core/types';

export { useWeb3Modal } from '@web3modal/wagmi/react';

type Mainnet = typeof mainnet;
type Holesky = typeof holesky;

export const createWagmiConfig = <
  TChainId extends '1' | '17000',
  TChains extends [Mainnet] | [Holesky] = TChainId extends '1' ? [Mainnet] : [Holesky],
>(
  chainId: TChainId,
  projectId: string,
  metadata: Web3ConfigMetadata,
  rpcs: string[],
) => {
  type TTransports = Record<TChains[number]['id'], Transport>;
  return (
    chainId == '1'
      ? createConfig({
          ssr: true,
          chains: [mainnet],
          transports: {
            [mainnet.id]: fallback([
              ...rpcs.map((rpc) => http(rpc)),
              http('https://cloudflare-eth.com'),
              http('https://ethereum-rpc.publicnode.com'),
              http('https://eth.merkle.io'),
              http('https://eth.llamarpc.com'),
            ]),
          } as TTransports,
          connectors: [
            walletConnect({ projectId: projectId, showQrModal: false }),
            safe(),
            injected({ shimDisconnect: true }),
            coinbaseWallet({
              appName: metadata.name,
            }),
          ],
        })
      : createConfig({
          ssr: true,
          chains: [holesky],
          transports: {
            [holesky.id]: fallback([...rpcs.map((rpc) => http(rpc)), http()]),
          } as TTransports,
          connectors: [
            walletConnect({ projectId: projectId, showQrModal: false }),
            safe(),
            injected({ shimDisconnect: true }),
            coinbaseWallet({
              appName: metadata.name,
            }),
          ],
        })
  ) as Config<TChains, Record<TChains[number]['id'], Transport>>;
};
