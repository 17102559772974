import React, { isValidElement } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { TextV2 } from '../../foundations/V2/Text';

const tagCva = cva(
  'rounded-full py-1.5 px-3 w-fit flex items-center justify-center text-inversePrimaryFg',
  {
    variants: {
      color: {
        purple: 'bg-lavender-500',
        blue: ' bg-blue-200',
        orange: 'bg-accentOrange',
        beige: 'bg-accentBeige',
        green: 'bg-accentGreen',
        white: 'bg-accentWhite',
        warning: 'bg-warningFg',
        success: 'bg-successFg',
        danger: 'bg-dangerPrimaryFg',
        greenV2: 'bg-green-400',
        dangerSecondary: 'bg-dangerSecondaryFg',
      },
    },
    defaultVariants: {
      color: 'purple',
    },
  },
);

export type TagVariants = VariantProps<typeof tagCva>;

export interface TagProps extends TagVariants {
  children: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  dataCypress?: string;
}

export const Tag = React.forwardRef<HTMLDivElement, TagProps>(
  ({ children, color, className, dataCypress, ...props }, forwaredRef) => (
    <div className={tagCva({ color, className })} ref={forwaredRef} {...props}>
      {isValidElement(children) ? (
        children
      ) : (
        <TextV2 intent="TextXS" font="ibmPlexMono" dataCypress={dataCypress}>
          {children}
        </TextV2>
      )}
    </div>
  ),
);
