import { WriteContractErrorType } from 'viem';
import { Config, useWriteContract } from 'wagmi';
import { waitForTransactionReceipt } from 'wagmi/actions';

import { TransactionLifecycle, TransactionStatus } from '@/core/types';

export const useWriteContractLifecycle = (
  config: Config,
  {
    onTxnSuccess,
    onTxnRevert,
    onTxnSettled,
    onTxnError,
    onSuccess,
    onError,
    onSettled,
    onMutate,
    updateTransaction,
  }: TransactionLifecycle,
) => {
  return useWriteContract({
    mutation: {
      onMutate: () => {
        updateTransaction?.({ status: TransactionStatus.Pending });
        onMutate?.();
      },
      onError: (_error) => {
        updateTransaction?.({ status: TransactionStatus.Failed });
        onError?.(_error as WriteContractErrorType);
      },
      onSuccess: (hash) => {
        updateTransaction?.({ hash });
        onSuccess?.(hash);

        waitForTransactionReceipt(config, { hash, timeout: 1000 * 60 * 5 })
          .then((txnReceipt) => {
            if (txnReceipt.status === 'success') {
              updateTransaction?.({
                status: TransactionStatus.Success,
                blockNumber: txnReceipt.blockNumber,
              });
              onTxnSuccess?.(txnReceipt);
            } else if (txnReceipt.status === 'reverted') {
              onTxnRevert?.(txnReceipt);
            }
            onTxnSettled?.(txnReceipt);
          })
          .catch((error) => {
            console.log(error);
            onTxnError?.();
          });
      },
      onSettled: () => {
        onSettled?.();
      },
    },
  });
};
