import { cva } from 'class-variance-authority';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import { ComponentSize } from '../../types';
import { Button } from '../Button';
import { NumberDisplay } from '../NumberDisplay';
import { ValueLoader } from '../ValueLoader';

export interface NumericalInputProps {
  size?: Exclude<ComponentSize, ComponentSize.XS | ComponentSize.XL>;
  containerClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  clearIcon?: boolean;
  maxButton?: boolean;
  isRequired?: boolean;
  inputId: string;
  balance?: number;
  balanceLabel?: string;
  balanceLoading?: boolean;
  disabled?: boolean;
  register: UseFormRegister<FieldValues> | (() => void);
  onClear?: () => void;
  onMaxHandler?: () => void;
  maxButtonIntent?: 'primary' | 'secondary' | 'tertiary' | 'quarternary' | 'danger';
}

const inputContainerCva = cva(
  'flex items-center bg-white-50 rounded-md border border-black-100 outline-none font-abcRepro',
  {
    variants: {
      size: {
        [ComponentSize.SM]: ['px-4 py-2 text-TextS'],
        [ComponentSize.MD]: ['px-4 py-3 text-TextM'],
        [ComponentSize.LG]: ['p-4 text-TextL'],
      },
      intent: {
        default:
          'text-blue-700 hover:bg-inputHover focus-within:bg-inputFocus focus-within:outline-1 active:bg-inputFocus focus-within:bg-lightblue-100 focus-within:shadow-[0px_0px_0px_4px_rgba(255,255,255,0.24)]',
        disabled: 'text-disabledFg cursor-not-allowed',
      },
    },
    defaultVariants: {
      size: ComponentSize.MD,
      intent: 'default',
    },
  },
);

const numericalInputCva = cva(
  'tabular-nums bg-transparent placeholder:text-disabledFg placeholder:focus:text-disabledFg outline-none w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
  {
    variants: {
      size: {
        [ComponentSize.SM]: [],
        [ComponentSize.MD]: [],
        [ComponentSize.LG]: [],
      },
      intent: {
        default: 'text-blue-700',
        disabled: 'text-disabledFg placeholder:text-disabledFg cursor-not-allowed',
      },
    },
    defaultVariants: {
      size: ComponentSize.MD,
    },
  },
);

const maxButtonCva = cva(
  'flex items-center justify-center ml-2 border-2 border-blue-700 rounded-full py-3 hover:bg-blue-200 active:bg-blue-200 focus:bg-blue-200',
  {
    variants: {
      size: {
        [ComponentSize.SM]: ['h-3.5 w-auto'],
        [ComponentSize.MD]: ['h-4 w-auto'],
        [ComponentSize.LG]: ['h-5 w-auto'],
      },
    },
    defaultVariants: {
      size: ComponentSize.MD,
    },
  },
);

export function NumericalInput({
  size = ComponentSize.MD,
  containerClassName,
  inputClassName,
  placeholder,
  clearIcon,
  maxButton,
  balance,
  balanceLoading,
  balanceLabel,
  isRequired = false,
  inputId,
  disabled,
  register,
  onClear,
  onMaxHandler,
  maxButtonIntent = 'tertiary',
}: NumericalInputProps) {
  const intent = disabled ? 'disabled' : 'default';
  return (
    <div
      className={inputContainerCva({
        size,
        className: containerClassName,
        intent,
      })}
    >
      <input
        className={numericalInputCva({
          size,
          className: inputClassName,
          intent,
        })}
        type="number"
        step="any"
        disabled={disabled}
        {...register(inputId, { required: isRequired })}
        data-cypress={inputId}
        placeholder={placeholder}
      />
      {balanceLoading && (
        <div className="flex flex-col text-TextXS text-blue-700">
          <ValueLoader />
          <span className="text-TextXS">{balanceLabel}</span>
        </div>
      )}
      {!balanceLoading && (
        <div
          data-cypress="wallet-balance"
          className="flex flex-col items-end text-TextXS text-blue-700"
        >
          <span className="whitespace-nowrap">
            <NumberDisplay value={balance ? balance : 0} format="tokenAmount" />
          </span>
          <span className="text-TextXS">{balanceLabel}</span>
        </div>
      )}

      {(clearIcon || maxButton) && (
        <div className={maxButtonCva({ size, className: 'cursor-pointer' })}>
          {maxButton ? (
            <Button
              size={ComponentSize.XS}
              onClick={onMaxHandler}
              stopPropagation={true}
              intent={maxButtonIntent}
              disabled={disabled}
              dataCypress="maxButton"
            >
              MAX
            </Button>
          ) : (
            <div onClick={onClear}>
              <svg
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                fill="none"
                className="h-full w-full"
              >
                <path
                  d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
