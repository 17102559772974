import { curveCardinal } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import {
  AnimatedAreaSeries,
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  buildChartTheme,
  Tooltip,
  XYChart,
} from '@visx/xychart';

import { colors } from '@monorepo/tailwind-config';

type Data = Array<{ x: string | number } & Record<string, string | number>>;

interface LineChartProps {
  data: Data;
  colorMap: Record<string, string>;
  yValues: Array<keyof Data[number]>;
  renderAreaChart?: boolean;
  usd?: boolean; // Depricate
  yUnit?: string;
  xNumTicks?: number;
  yNumTicks?: number;
}

const LINE_STROKE_STYLES = {
  stroke: colors.white[500],
  strokeOpacity: 0.5,
  strokeWidth: 0.7,
} as const;

const defaultTheme = buildChartTheme({
  backgroundColor: colors.white[500],
  colors: [colors.blue[950], colors.mustard[950], colors.emerald[950], colors.lavender[950]],
  gridColor: colors.black[50],
  gridColorDark: colors.black[50],
  tickLength: 8,
});

export function LineChart({
  data = [],
  colorMap = {},
  yValues = ['y'],
  renderAreaChart = false,
  usd = false,
  yUnit = '',
  xNumTicks = 4,
  yNumTicks = 4,
}: LineChartProps) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <XYChart
          height={height}
          width={width}
          xScale={{ type: 'band', clamp: true }}
          yScale={{ type: 'linear', clamp: true }}
          theme={defaultTheme}
          margin={{ top: 20, right: 40, bottom: 40, left: 60 }}
        >
          <defs>
            <linearGradient id="area-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgba(202, 223, 255, 0.15)" />
              <stop offset="95.55%" stopColor="rgba(127, 86, 217, 0)" />
            </linearGradient>
          </defs>
          <AnimatedAxis
            orientation="bottom"
            tickLabelProps={() => ({
              className: 'font-ibmPlexMono',
              dx: '0.33em',
              fill: colors.lightblue[50],
            })}
            tickLineProps={LINE_STROKE_STYLES}
            numTicks={xNumTicks}
            stroke="none" // NOTE: AnimatedGrid will handle tthis
          />
          <AnimatedAxis
            orientation="left"
            tickLabelProps={() => ({
              className: 'font-ibmPlexMono',
              dy: '0.33em',
              fill: colors.lightblue[50],
            })}
            tickLineProps={LINE_STROKE_STYLES}
            numTicks={yNumTicks}
            stroke="none" // NOTE: AnimatedGrid will handle tthis
          />
          <AnimatedGrid columns={false} numTicks={yNumTicks} lineStyle={LINE_STROKE_STYLES} />
          {yValues.map((yValue) =>
            renderAreaChart ? (
              <AnimatedAreaSeries
                dataKey={yValue}
                data={data}
                xAccessor={(d) => d.x}
                yAccessor={(d) => d[yValue] ?? null}
                fill="url(#area-gradient)"
                stroke={colorMap[yValue]}
                curve={curveCardinal}
              />
            ) : (
              <AnimatedLineSeries
                dataKey={yValue}
                data={data}
                xAccessor={(d) => d.x}
                colorAccessor={(dataKey) => colorMap[dataKey]}
                yAccessor={(d) => d[yValue] ?? null}
              />
            ),
          )}
          <Tooltip<Data[number]>
            snapTooltipToDatumX
            snapTooltipToDatumY
            showVerticalCrosshair
            verticalCrosshairStyle={{
              stroke: colors.black[500],
              strokeWidth: 2,
            }}
            renderTooltip={({ tooltipData }) => (
              <div style={{ color: colors.blue[950] }}>
                <p className="text-inversePrimaryFg text-TextS">
                  {`${usd ? '$' : ''}${
                    tooltipData?.nearestDatum?.datum[tooltipData?.nearestDatum?.key]
                  } ${yUnit ?? ''}`}
                </p>
                <p className="text-inversePrimaryFg text-TextXS">
                  {tooltipData?.nearestDatum?.datum?.x ?? 'Unknown'}
                </p>
              </div>
            )}
          />
        </XYChart>
      )}
    </ParentSize>
  );
}
