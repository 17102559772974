import React, { ComponentType } from 'react';

import { hashKey, QueryClient } from '@tanstack/react-query';
import { createWeb3Modal, Web3ModalOptions } from '@web3modal/wagmi/react';
import { Config } from 'wagmi';

import { colors } from '@monorepo/tailwind-config';

import { serializeJsonWithBigInts } from '@/core/util';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryKeyHashFn: (keys) => {
        const serializableKeys = keys.map((key) => {
          try {
            if (!key || JSON.stringify(key)) return key;
          } catch {
            return serializeJsonWithBigInts(key);
          }
        });
        return hashKey(serializableKeys);
      },
    },
  },
});

export function Web3Provider({
  WagmiProvider: WagmiProvider_,
  QueryClientProvider: QueryClientProvider_,
  projectId,
  wagmiConfig,
  themeVariables,
  children,
}: {
  WagmiProvider: React.FC<{ config: Config; children: React.ReactNode }>;
  QueryClientProvider: ComponentType<{
    client: QueryClient;
    children: React.ReactNode;
  }>;
  projectId: string;
  wagmiConfig: Config;
  themeVariables?: Web3ModalOptions<Config>['themeVariables'];
  children: React.ReactNode;
}) {
  createWeb3Modal({
    wagmiConfig: wagmiConfig,
    projectId: projectId,
    featuredWalletIds: ['971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'],
    themeVariables: {
      '--w3m-color-mix': `${colors.lightblue[100]}`,
      '--w3m-color-mix-strength': 20,
      ...themeVariables,
    },
    themeMode: 'light',
  });
  return (
    <WagmiProvider_ config={wagmiConfig}>
      <QueryClientProvider_ client={queryClient}>{children}</QueryClientProvider_>
    </WagmiProvider_>
  );
}
