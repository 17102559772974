import { useMemo } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Dialog from '@radix-ui/react-dialog';

import { TransactionModalOnchainTxn, TransactionModalTxn, TransactionStatus } from '@/core/types';
import { checkAllTransactionModalTxnStatuses, cn } from '@/core/util';

import { TextV2 } from '../../foundations/V2/Text';
import { TransactionStatusIcon } from '../TransactionStatusIcon';
import { Button as ButtonV2 } from '../V2/Button';
import { TransactionLink } from './TransactionLink';

const isOnchainTxn = (
  transaction: TransactionModalTxn,
): transaction is TransactionModalOnchainTxn => {
  return 'hash' in transaction;
};

const subTxnProgress = (
  transaction: TransactionModalTxn | TransactionModalTxn[],
  subIndex: number | null,
) => {
  if (typeof subIndex !== 'number' || !Array.isArray(transaction) || transaction.length === 1) {
    return null;
  }
  const subTxn = transaction[subIndex];
  const isSuccess = subTxn.status === TransactionStatus.Success;
  return `(${subIndex + Number(isSuccess)}/${transaction.length})`;
};

interface TransactionStatusModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  transactions: (TransactionModalTxn | TransactionModalTxn[])[];
  title: string;
  description?: string;
  resetFailedTransactions: () => void;
  successModal?: React.ReactNode;
  showDone?: boolean;
  onDone?: () => void;
  doneLabel?: string;
  buttonContent?: React.ReactNode;
  blockExplorerURL: string;
  onClose?: () => void;
}

export function TransactionStatusModal({
  isOpen,
  setIsOpen,
  transactions,
  title,
  description,
  resetFailedTransactions,
  successModal,
  showDone = false,
  onDone,
  doneLabel = 'Done',
  buttonContent,
  blockExplorerURL,
  onClose,
}: TransactionStatusModalProps) {
  const noTxnStarted = transactions.every(
    checkAllTransactionModalTxnStatuses('every', TransactionStatus.NotStarted),
  );
  const isTxnError = transactions.some(
    checkAllTransactionModalTxnStatuses('some', TransactionStatus.Failed),
  );
  const isTxnSuccess = transactions.every(
    checkAllTransactionModalTxnStatuses('every', TransactionStatus.Success),
  );

  const currentTxnForSteps = useMemo(() => {
    return transactions.map((txn) => {
      if (!Array.isArray(txn)) {
        return { transaction: txn, subIndex: null };
      }

      if (!txn.length) {
        return { transaction: undefined, subIndex: null };
      }

      for (let i = txn.length - 1; i > 0; i--) {
        const isPending = txn[i].status === TransactionStatus.Pending;
        const isSuccess = txn[i].status === TransactionStatus.Success;
        const isFailed = txn[i].status === TransactionStatus.Failed;
        if (isFailed || isSuccess || isPending) {
          return { transaction: txn[i], subIndex: i };
        }
      }

      return { transaction: txn[0], subIndex: 0 };
    });
  }, [transactions]);

  const showSuccessModal = isTxnSuccess && !!successModal;

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay
          onClick={noTxnStarted || isTxnError ? () => setIsOpen(false) : undefined}
          className={`fixed top-0 z-[20] h-screen w-screen ${
            showSuccessModal ? 'bg-emerald-800' : 'bg-black/40'
          }`}
        />
        <Dialog.Content className="fixed left-0 top-1/4 z-[25] mx-4 w-[calc(100%-32px)] transform rounded-lg bg-white outline-none sm:left-1/2 sm:top-1/2 sm:mx-auto sm:w-[440px] sm:-translate-x-1/2 sm:-translate-y-1/2">
          <div>
            {showSuccessModal ? (
              <>{successModal}</>
            ) : (
              <>
                <Dialog.DialogTitle className="m-0 p-0">
                  <TextV2
                    intent="TextXL"
                    weight="medium"
                    className={`mx-4 mt-4 block ${description ? 'mb-1' : 'mb-5'}`}
                  >
                    {title}
                  </TextV2>
                </Dialog.DialogTitle>
                {description && (
                  <TextV2 intent="TextS" weight="medium" className="mx-4 mb-5 block text-blue-400">
                    {description}
                  </TextV2>
                )}
                <div className="mx-4 mb-4 flex flex-row rounded-lg border border-blue-100 p-4">
                  <div className="flex w-full flex-col justify-between gap-[3.25rem]">
                    {currentTxnForSteps.map(
                      ({ transaction, subIndex }, index) =>
                        transaction && (
                          <div
                            key={index}
                            className={cn(
                              'flex w-full flex-row items-center justify-between gap-3',
                              '[&:not(:last-child)>div:first-child]:after:bg-blue-100',
                              // Lines between the status icons
                              '[&:not(:last-child)>div:first-child]:after:rounded-lg',
                              '[&:not(:last-child)>div:first-child]:after:absolute',
                              '[&:not(:last-child)>div:first-child]:after:left-[calc(50%-1px)]',
                              '[&:not(:last-child)>div:first-child]:after:top-6',
                              '[&:not(:last-child)>div:first-child]:after:h-12',
                              '[&:not(:last-child)>div:first-child]:after:w-[2px]',
                            )}
                          >
                            <div className="relative h-5 min-w-5 shrink-0">
                              <TransactionStatusIcon
                                status={transaction.status}
                                className="shrink-0"
                              />
                            </div>
                            <div className="flex w-full shrink flex-row items-center justify-between">
                              <div className="flex flex-col">
                                <TextV2 weight="medium">
                                  {transaction.label}{' '}
                                  {subTxnProgress(transactions[index], subIndex)}
                                </TextV2>
                                <TextV2 intent="TextS" weight="medium">
                                  {transaction.subLabel}
                                </TextV2>
                              </div>
                              {isOnchainTxn(transaction) && transaction.hash && (
                                <TransactionLink
                                  hash={transaction.hash}
                                  blockExplorerURL={blockExplorerURL}
                                />
                              )}
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                </div>
                {isTxnError && (
                  <>
                    <XMarkIcon
                      className="absolute right-4 top-4 h-6 w-6 cursor-pointer text-blue-400 hover:text-blue-700"
                      onClick={() => {
                        onClose?.();
                        setIsOpen(false);
                        resetFailedTransactions();
                      }}
                    />
                    <div className="mb border-t border-blue-100 p-4">
                      {currentTxnForSteps.map(({ transaction }, index) =>
                        transaction && transaction.status === TransactionStatus.Failed ? (
                          <ButtonV2 className="w-full" onClick={transaction.write} key={index}>
                            {transaction.label}
                          </ButtonV2>
                        ) : null,
                      )}
                    </div>
                  </>
                )}
                {isTxnSuccess && showDone && (
                  <div className="mb-4 px-4">
                    <ButtonV2
                      className="w-full"
                      onClick={() => {
                        setIsOpen(false);
                        onDone?.();
                      }}
                    >
                      {doneLabel}
                    </ButtonV2>
                    {buttonContent}
                  </div>
                )}
              </>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
