import { forwardRef, useEffect, useState } from 'react';

import { CheckIcon } from '@heroicons/react/24/outline';
import * as RdxCheckbox from '@radix-ui/react-checkbox';
import { cva } from 'class-variance-authority';

import { cn } from '@/core/util';

import { TextV2 } from '../../foundations/V2/Text';
import { ComponentSize } from '../../types';

import type { VariantProps } from 'class-variance-authority';
import type { TextProps } from '../../foundations/V2/Text';

export interface CheckboxProps
  extends VariantProps<typeof checkboxCva>,
    React.ComponentPropsWithoutRef<typeof RdxCheckbox.Root> {
  stopPropagation?: boolean;
  checkboxClassName?: string;
  labelProps?: Omit<TextProps, 'children'>;
  'data-cypress'?: string;
}

const checkboxCva = cva('flex items-center', {
  variants: {
    intent: {
      primary: [
        // Univsersal
        'flex items-center justify-center outline -outline-offset-1 ring-blue-700 ring-0 transition-all',
        'focus:!outline-transparent focus:ring-1 [&:hover>span]:scale-[1.15] [&:focus>span]:scale-[1.15]',
        '[&>span]:scale-100 [&>span]:transition-all',
        'text-white [&>span>div]:bg-white [&>span>div]:rounded-md',
        // Checked/Indeterminate
        'bg-blue-700 outline-transparent ring-blue-800',
        'hover:bg-blue-800 [&:not(:focus)]:hover:outline-blue-800',
        // Unchecked
        'data-[state=unchecked]:bg-white data-[state=unchecked]:outline-blue-300',
        'data-[state=unchecked]:hover:bg-blue-100 [&:not(:focus)]:data-[state=unchecked]:hover:outline-blue-400',
      ],
      disabled: [
        'flex items-center justify-center bg-blue-50 outline -outline-offset-1 outline-1 outline-blue-200 rounded-[4px] cursor-not-allowed',
        'text-blue-200 [&>span>div]:bg-blue-200',
      ],
    },
    size: {
      [ComponentSize.SM]: [
        'size-4 rounded-[4px]',
        '[&_svg]:w-[10px] [&_svg]:h-[10px] [&>span>div]:w-[10px] [&>span>div]:h-[2px]',
      ],
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: ComponentSize.SM,
  },
});

export const Checkbox = forwardRef<React.ElementRef<typeof RdxCheckbox.Root>, CheckboxProps>(
  (
    {
      children,
      className,
      defaultChecked,
      checked,
      intent = 'primary',
      stopPropagation,
      disabled = false,
      checkboxClassName,
      onCheckedChange,
      labelProps,
      'data-cypress': dataCypress,
      ...props
    },
    ref,
  ) => {
    const [checkedState, setCheckedState] = useState<CheckboxProps['checked']>(
      defaultChecked ?? checked ?? false,
    );

    useEffect(() => {
      setCheckedState(checked ?? false);
    }, [checked]);

    const Slot: keyof JSX.IntrinsicElements = children ? 'label' : 'div';

    const CheckmarkIndicator = (
      <>{checkedState === 'indeterminate' ? <div /> : <CheckIcon strokeWidth={4.8} />}</>
    );

    const LabelContent = (
      <>
        {children && ['string', 'number', 'boolean'].includes(typeof children) ? (
          <TextV2 {...labelProps} className={cn('leading-none', labelProps?.className)}>
            {children}
          </TextV2>
        ) : (
          <>{children}</>
        )}
      </>
    );

    return (
      <Slot
        className={cn('flex h-fit items-center gap-2', className)}
        onClick={(evt) => {
          if (stopPropagation) {
            evt.stopPropagation();
            evt.preventDefault();
          }
        }}
      >
        <RdxCheckbox.Root
          ref={ref}
          disabled={intent === 'disabled' || disabled}
          className={checkboxCva({
            className: checkboxClassName,
            intent: disabled ? 'disabled' : intent,
          })}
          checked={checkedState}
          onCheckedChange={(nextChecked) => {
            setCheckedState(nextChecked);
            onCheckedChange?.(nextChecked);
          }}
          data-cypress={dataCypress}
          {...props}
        >
          <RdxCheckbox.Indicator>{checkedState && CheckmarkIndicator}</RdxCheckbox.Indicator>
        </RdxCheckbox.Root>
        {LabelContent}
      </Slot>
    );
  },
);

Checkbox.displayName = 'Checkbox';
