import { cva, VariantProps } from 'class-variance-authority';

import { TextV2 } from '../../foundations/V2/Text';
import { InfoPopover } from '../InfoPopover';
import { ValueLoader } from '../ValueLoader';

const displayCardCva = cva('flex flex-col whitespace-nowrap', {
  variants: {
    intent: {
      primary: ['border border-tertiaryBg rounded-lg p-4'],
      secondary: ['bg-secondaryBg px-4 py-6 sm:px-6 lg:px-12 sm:py-8 lg:py-12 rounded-lg'],
    },
  },
});

type DisplayCardVariants = VariantProps<typeof displayCardCva>;

export interface DisplayCardProps extends DisplayCardVariants {
  stat: React.ReactNode;
  label: string;
  className?: string;
  statClassName?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  toolTipMessage?: string;
}

export function DisplayCard({
  stat,
  label,
  className,
  intent,
  statClassName,
  isLoading,
  children,
  toolTipMessage,
}: DisplayCardProps) {
  return (
    <div className={displayCardCva({ className, intent })}>
      <div className={`flex flex-row justify-between ${statClassName}`}>
        {isLoading ? <ValueLoader /> : stat}
        {toolTipMessage && <InfoPopover>{toolTipMessage}</InfoPopover>}
      </div>
      <TextV2 intent="TextXS" font="ibmPlexMono" className="truncate text-blue-700">
        {label}
      </TextV2>
      {children && children}
    </div>
  );
}
