export function WalletIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16699 3.33317C3.70675 3.33317 3.33366 3.70627 3.33366 4.1665C3.33366 4.62674 3.70676 4.99984 4.16699 4.99984L15.8337 4.99984C17.2144 4.99984 18.3337 6.11913 18.3337 7.49984V15.8332C18.3337 17.2139 17.2144 18.3332 15.8337 18.3332H4.16699C2.78628 18.3332 1.66699 17.2139 1.66699 15.8332V4.1665C1.66699 2.78579 2.78628 1.6665 4.16699 1.6665H14.167C14.6272 1.6665 15.0003 2.0396 15.0003 2.49984C15.0003 2.96007 14.6272 3.33317 14.167 3.33317H4.16699ZM13.7503 10.4165C13.06 10.4165 12.5003 10.9761 12.5003 11.6665C12.5003 12.3569 13.06 12.9165 13.7503 12.9165C14.4407 12.9165 15.0003 12.3569 15.0003 11.6665C15.0003 10.9761 14.4407 10.4165 13.7503 10.4165Z"
        fill="currentColor"
      />
    </svg>
  );
}
