import { cn } from '@/core/util';

export interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export function Card({ children, className }: CardProps) {
  return <div className={cn('bg-secondaryBg rounded-lg p-4', className || '')}>{children}</div>;
}
