import { cn, formatBigIntToFloatString } from '@/core/util';

export interface NumberDisplayProps {
  value?: bigint | number | null;
  format: 'usd' | 'tokenAmount' | 'percentage' | 'decimal' | 'compact' | 'integer';
  decimals?: number;
  precision?: number;
  truncate?: boolean;
  className?: string;
  dataCypress?: string;
}

export function NumberDisplay({
  value,
  format,
  decimals,
  precision,
  className,
  dataCypress,
}: NumberDisplayProps) {
  if (value === null || value === undefined) return null;
  let displayValue = '';
  if (typeof value === 'number') {
    switch (format) {
      case 'usd':
        if (value < 0.01 && value > 0) {
          displayValue = '< $0.01';
        } else {
          displayValue = value.toLocaleString(undefined, {
            style: 'currency',
            currency: 'USD',
          });
        }
        break;
      case 'tokenAmount':
      case 'decimal':
        if (value < 0.0001 && value > 0) {
          displayValue = '< 0.0001';
        } else {
          displayValue = value.toLocaleString(undefined, {
            minimumFractionDigits: decimals ?? 4,
            maximumFractionDigits: decimals ?? 4,
          });
        }
        break;
      case 'percentage':
        displayValue = `${
          decimals
            ? value.toLocaleString(undefined, {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
              })
            : value
        }%`;
        break;
      case 'compact':
        displayValue = Intl.NumberFormat('en', {
          notation: 'compact',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
        break;
      case 'integer':
        displayValue = value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
        break;
      default:
        break;
    }
  } else {
    // bigint
    switch (format) {
      case 'usd':
        displayValue = `${formatBigIntToFloatString(value, precision || 16, decimals || 2)}`;
        if (displayValue === '$0.00' && value > 0) {
          displayValue = '< $0.01';
        }
        break;
      case 'tokenAmount':
        displayValue = formatBigIntToFloatString(value, precision || 18, decimals || 4);
        if (displayValue === '0.0000' && value > 0) {
          displayValue = '< 0.0001';
        }
        break;
      case 'percentage':
        displayValue = `${formatBigIntToFloatString(value, precision || 18, decimals || 2)}%`;
        break;
      case 'integer':
        displayValue = formatBigIntToFloatString(value, precision || 18, 0);
        break;
      default:
        break;
    }
  }

  return (
    <span className={cn('tabular-nums', className)} data-cypress={dataCypress}>
      {displayValue}
    </span>
  );
}
