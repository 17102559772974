import { forwardRef } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { ComponentSize } from '../../types';

const iconButtonCva = cva(
  'outline-none h-fit w-fit border-black-100 rounded-full inline-block outline-none disabled:cursor-not-allowed',
  {
    variants: {
      intent: {
        primary: [
          'bg-interactivePrimary border-2 border-transparent hover:bg-interactivePrimaryHover active:bg-interactivePrimaryPress focus:bg-interactivePrimaryFocus',
          'text-inversePrimaryFg hover:text-inverseTertiaryFg',
        ],
        secondary: [
          'bg-interactiveSecondary border-2 border-transparent hover:bg-interactiveSecondaryHover active:bg-interactiveSecondaryPress focus:bg-interactiveSecondaryFocus',
          'text-blue-700 hover:text-tertiaryFg',
        ],
        tertiary: [
          'border hover:bg-blue-100 active:bg-blue-100  focus:bg-blue-100',
          'text-blue-700 hover:text-tertiaryFg',
        ],
        quarternary: [
          'bg-interactiveQuarternary border-2 border-transparent hover:bg-interactiveQuarternaryHover active:bg-interactiveQuarternaryPress focus:bg-interactiveQuarternaryFocus',
          'text-blue-700 hover:text-tertiaryFg',
        ],
        danger: [
          'bg-dangerPrimary border-2 border-transparent hover:bg-dangerPrimaryHover active:bg-dangerPrimaryPress focus:bg-dangerPrimaryFocus',
          'text-dangerPrimaryFg hover:text-dangerPrimaryFgHover active:text-dangerPrimaryFgPress focus:text-dangerPrimaryFgFocus',
        ],
      },
      size: {
        [ComponentSize.XS]: ['p-1.5', 'text-ShortTextXS'],
        [ComponentSize.SM]: ['p-2', 'text-ShortTextS'],
        [ComponentSize.MD]: ['p-3', 'text-ShortTextM'],
        [ComponentSize.LG]: ['p-4', 'text-ShortTextL'],
      },
      focus: {
        on: ['focus:border-borderFocus'],
        off: [],
      },
    },
    defaultVariants: {
      intent: 'primary',
      size: ComponentSize.MD,
      focus: 'on',
    },
  },
);

const containerCva = cva('flex items-center justify-center', {
  variants: {
    size: {
      [ComponentSize.XS]: 'w-4 h-4',
      [ComponentSize.SM]: 'w-5 h-5',
      [ComponentSize.MD]: 'w-6 h-6',
      [ComponentSize.LG]: 'w-7 h-7',
    },
  },
  defaultVariants: {
    size: ComponentSize.MD,
  },
});

type IconButtonVariants = VariantProps<typeof iconButtonCva>;

export interface IconButtonProps extends IconButtonVariants {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const IconButton = forwardRef(
  (
    {
      children,
      intent,
      size,
      focus,
      className,
      onClick,
      ...props
    }: IconButtonProps & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'focus'>,
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <button
      {...props}
      onClick={(evt) => {
        (evt.target as HTMLButtonElement).blur();
        (evt.currentTarget as HTMLButtonElement).blur();
        onClick?.(evt);
      }}
      className={iconButtonCva({ intent, size, focus, className })}
      ref={ref}
    >
      <div className={containerCva({ size })}>{children}</div>
    </button>
  ),
);
