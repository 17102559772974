import { clsx } from 'clsx';
import { formatDistanceToNow } from 'date-fns';
import ipRangeCheck from 'ip-range-check';
import { extendTailwindMerge } from 'tailwind-merge';
import { Address, isAddressEqual } from 'viem';
import { create } from 'zustand';

import { FONT_FAMILIES, TAILWIND_FONT_SIZES } from '@monorepo/tailwind-config';

import { StoreTransaction, TransactionModalTxn, TransactionStatus } from '../types';

import type { ClassValue } from 'clsx';

export type Prettify<T> = {
  // [K in keyof T]: T[K];
  [K in keyof T]: T[K] extends object ? Prettify<T[K]> : T[K];
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
} & unknown;

const twMerge = extendTailwindMerge({
  classGroups: {
    'font-size': Object.keys(TAILWIND_FONT_SIZES).map((key) => `text-${key}`),
    'font-family': Object.keys(FONT_FAMILIES).map((key) => `font-${key}`),
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function bigIntReplacer(key: string, value: unknown): unknown {
  if (typeof value === 'bigint') {
    return value.toString() + 'n';
  }
  return value;
}

export function bigIntReviver(key: string, value: unknown): unknown {
  if (typeof value === 'string' && /^\d+n$/.test(value)) {
    return BigInt(value.slice(0, -1));
  }
  return value;
}

/**
 * Serializes an object to JSON, converting BigInts to strings
 * @param obj The object to serialize
 * @returns The serialized JSON string
 * @example
 * ```ts
 * const obj = { bigInt: BigInt(123) };
 * const serialized = serializeJsonWithBigInts(obj);
 * console.log(serialized); // {"bigInt":"123"}
 * ```
 */
export const serializeJsonWithBigInts = (obj: unknown) => {
  return JSON.stringify(obj, bigIntReplacer);
};

/**
 * Parses a JSON string that contains BigInt strings
 * @param obj The string to parse
 * @returns The parsed JSON
 * @example
 * ```ts
 * const json = '{"bigInt":"123n"}';
 * const parsed = parseJsonWithBigInts(json);
 * console.log(parsed); // { bigInt: 123n }
 * ```
 */
export const parseJsonWithBigInts = (json: string) => {
  return JSON.parse(json, bigIntReviver);
};

export function formatBigIntToFloatString(bigint: bigint, precision: number, decimals: number) {
  let str = bigint.toString();

  while (str.length < precision + 1) {
    str = '0' + str;
  }

  const index = str.length - precision;
  const wholePart = Number(str.slice(0, index)).toLocaleString(undefined);
  const fractionalPart = str.slice(index, index + decimals);

  const formattedFractionalPart = fractionalPart.padEnd(decimals, '0');

  return `${wholePart}${
    decimals > 0 ? Intl.NumberFormat(undefined).format(1.1).charAt(1) : ''
  }${formattedFractionalPart}`;
}

export function formatBigIntToFloat(bigint: bigint, precision: number, decimals: number) {
  return parseFloat(formatBigIntToFloatString(bigint, precision, decimals).replace(/,/g, ''));
}

export function openInNewTab(url: string): void {
  const newWindow = global.window?.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}

export type status = 'success' | 'reverted';
export enum transactionEvent {
  approval = 'approval',
  deposit = 'deposit',
  queueWithdraw = 'queueWithdraw',
  completeWithdraw = 'completeWithdraw',
  delegate = 'delegate',
  undelegate = 'undelegate',
  queueWithdrawals = 'queueWithdrawals',
}

export const sendTxnDataToCypress = (transactionEvent: transactionEvent, status: status) => {
  const event = new CustomEvent(transactionEvent, {
    detail: status,
  });
  window.dispatchEvent(event);
};

export const shortenAddress = (address?: string): string => {
  if (!address) return '';
  if (typeof address !== 'string') return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const returnUnauthorized = (): Response => {
  return new Response('Access Denied', { status: 403 });
};

export const checkRestrictedGeoLocation = (country: string): boolean => {
  return ['IR', 'KP', 'SY', 'CU', 'RU', 'UA'].includes(country);
};

export const checkValidCloudflareIp = (
  env: 'production' | 'preview' | 'development',
  request: Request,
  contractEnv?: 'mainnet-ethereum' | 'testnet-holesky' | 'preprod-holesky',
): Response | null => {
  if (env !== 'production' || contractEnv === 'preprod-holesky') return null;
  const ips = request.headers.get('x-forwarded-for');
  if (!ips) return returnUnauthorized();

  // https://www.cloudflare.com/ips/
  const allowedIps = [
    '173.245.48.0/20',
    '103.21.244.0/22',
    '103.22.200.0/22',
    '103.31.4.0/22',
    '141.101.64.0/18',
    '108.162.192.0/18',
    '190.93.240.0/20',
    '188.114.96.0/20',
    '197.234.240.0/22',
    '198.41.128.0/17',
    '162.158.0.0/15',
    '104.16.0.0/13',
    '104.24.0.0/14',
    '172.64.0.0/13',
    '131.0.72.0/22',
    '2400:cb00::/32',
    '2606:4700::/32',
    '2803:f800::/32',
    '2405:b500::/32',
    '2405:8100::/32',
    '2a06:98c0::/29',
    '2c0f:f248::/32',
  ];

  const formattedIps: string[] = ips.replace(/\s/g, '').split(',');
  return formattedIps.some((ip) => ipRangeCheck(ip, allowedIps)) ? null : returnUnauthorized();
};

type LocalStorageItemWithExpirationType = {
  value: string;
  expiry: number;
};

export const setLocalStorageItemWithExpiration = (key: string, value: string, ttl: number) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageItemWithExpiration = (key: string) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr) as LocalStorageItemWithExpirationType;
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

/**
 * Function to convert a base64 encoded byte string to a Uint8Array of bytes
 * @param base64 base64 encoded byte string
 * @returns {Uint8Array} Uint8Array of bytes
 */
export const b64ToBytes = (base64: string): Uint8Array => {
  return Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
};

/**
 * Function to convert an array of base64 encoded byte strings to a single Uint8Array of bytes
 * @param base64Arr array of base64 encoded byte strings
 * @returns {Uint8Array} Uint8Array of bytes
 */
export const b64ArrayToBytes = (base64Arr: string[]): Uint8Array => {
  return Uint8Array.from(base64Arr.map(atob).join(''), (c) => c.charCodeAt(0));
};

export const unixTimestampDistanceToNow = (timestamp?: number): string => {
  if (!timestamp) return '';
  return formatDistanceToNow(new Date(timestamp * 1000), { addSuffix: true });
};

export const checkAllTransactionModalTxnStatuses =
  (checkFxn: 'some' | 'every', status: TransactionStatus) =>
  (transaction: TransactionModalTxn | TransactionModalTxn[]) => {
    if (Array.isArray(transaction)) {
      return transaction[checkFxn]((txn) => txn.status === status);
    }
    return transaction.status === status;
  };

interface DefaultTransactionStore {
  transaction: StoreTransaction;
  updateTransaction: (updatedTxn: Partial<StoreTransaction>) => void;
  resetTransaction: () => void;
  resetFailedTransaction: () => void;
}

export const NEW_STORE_TRANSACTION: StoreTransaction = {
  status: TransactionStatus.NotStarted,
  blockNumber: null,
  hash: null,
} as const;

export const createDefaultTransactionStore = () => {
  return create<DefaultTransactionStore>((set) => ({
    transaction: NEW_STORE_TRANSACTION,
    updateTransaction: (updatedTxn) =>
      set((state) => ({
        transaction: { ...state.transaction, ...updatedTxn },
      })),
    resetTransaction: () => set({ transaction: NEW_STORE_TRANSACTION }),
    resetFailedTransaction: () =>
      set((state) => ({
        transaction: {
          ...state.transaction,
          status:
            state.transaction.status === TransactionStatus.Failed
              ? TransactionStatus.NotStarted
              : state.transaction.status,
        },
      })),
  }));
};

interface OffChainStoreTransaction {
  status: TransactionStatus;
}
interface OffChainTransactionStore {
  transaction: OffChainStoreTransaction;
  updateTransaction: (updatedTxn: OffChainStoreTransaction) => void;
  resetTransaction: () => void;
  resetFailedTransaction: () => void;
}

export const NEW_OFFCHAIN_TRANSACTION: OffChainStoreTransaction = {
  status: TransactionStatus.NotStarted,
} as const;

export const createOffchainTransactionStore = () => {
  return create<OffChainTransactionStore>((set) => ({
    transaction: NEW_OFFCHAIN_TRANSACTION,
    updateTransaction: (updatedTxn) =>
      set(() => ({
        transaction: { status: updatedTxn.status },
      })),
    resetTransaction: () => set({ transaction: NEW_OFFCHAIN_TRANSACTION }),
    resetFailedTransaction: () =>
      set((state) => ({
        transaction: {
          status:
            state.transaction.status === TransactionStatus.Failed
              ? TransactionStatus.NotStarted
              : state.transaction.status,
        },
      })),
  }));
};

/**
 * Casts a value to a specific type
 * @param value The value to cast
 * @returns The value cast to the specified type
 * @example
 * ```ts
 * const asString: string = "0x00000...00000";
 * const asAddress: Address = asType<Address>(asString);
 * ```
 */
export const asType = <T>(value: unknown): T => value as T;

/**
 * Compares two addresses for equality
 * @dev A wrapper for the viem isAddressEqual function that doesn't throw
 *      errors if either address is invalid
 * @param a Address #1
 * @param b Address #2
 * @returns {boolean} True if the addresses are equal, false otherwise
 */
export const isEqualAddress = (a?: string, b?: string): boolean => {
  try {
    // Use the isAddressEqual function from the viem package to check
    return isAddressEqual(asType<Address>(a), asType<Address>(b));
  } catch {
    // If it throws, then at least one address is invalid, so... no.
    // They're not equal addresses.
    return false;
  }
};

export const imageURIToFileList = async (
  url: string,
  filename: string,
  mimeType = 'image/png',
): Promise<FileList | null> => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch the image. Status: ${response.status}`);
    }

    const blob = await response.blob();

    if (blob.type !== mimeType) {
      throw new Error(`The fetched resource is not ${mimeType}`);
    }

    const file = new File([blob], filename, { type: mimeType });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    return dataTransfer.files;
  } catch (error) {
    console.error('Error converting URL to file:', error);
    return null;
  }
};
