import tailwindAnimate from 'tailwindcss-animate';

import { ANIMATION, colors, FONT_FAMILIES, KEYFRAMES, TAILWIND_FONT_SIZES } from './values';

export * from './values';

export default {
  content: [
    './src/**/*.tsx',
    './**/app/**/*.tsx',
    './**/components/**/*.tsx',
    './**/pages/**/*.tsx',
    '!./node_modules',
    '!./dist',
    '!./.next',
  ],
  theme: {
    extend: {
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic': 'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
      },

      boxShadow: {
        up: '0 -8px 12px -2px rgba(0, 0, 0, 0.05), 0 -4px 8px -2px rgba(0, 0, 0, 0.03)',
      },

      colors: { ...colors },
      fontSize: { ...TAILWIND_FONT_SIZES },
      fontFamily: {
        ...FONT_FAMILIES,
      },
      keyframes: {
        ...KEYFRAMES,
      },
      animation: {
        ...ANIMATION,
      },
    },
  },
  plugins: [tailwindAnimate],
};
