'use client';

import { useEffect, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Portal from '@radix-ui/react-portal';
import * as Toast from '@radix-ui/react-toast';
import { cva, VariantProps } from 'class-variance-authority';

const notificationToastCva = cva(
  'relative border bg-blue-700 rounded-md py-4 px-5 transition data-[swipe=move]:translate-x-[--radix-toast-swipe-move-x] data-[swipe=cancel]:translate-x-0 data-[swipe=end]:animation-[toastSlideRight_100ms_ease-out] z-[60]',
  {
    variants: {
      type: {
        success: ['text-emerald-300'],
        danger: ['text-red-300'],
        warning: ['text-mustard-300'],
        info: ['text-white-950'],
      },
    },
    defaultVariants: {
      type: 'info',
    },
  },
);

type NotificationVariants = VariantProps<typeof notificationToastCva>;

export interface NotificationToastProps extends NotificationVariants {
  children: React.ReactNode;
  className?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  duration?: number;
}

export function NotificationToast({
  children,
  type,
  className,
  open,
  onOpenChange,
  duration = 17000,
}: NotificationToastProps) {
  return (
    <Toast.Root
      className={notificationToastCva({ type, className })}
      open={open}
      onOpenChange={onOpenChange}
      duration={duration}
    >
      <XMarkIcon
        className="absolute right-2 top-2 h-5 w-5 cursor-pointer hover:brightness-125"
        onClick={() => onOpenChange?.(false)}
      />
      {children}
    </Toast.Root>
  );
}

const toastViewportCva = cva('fixed w-80 p-4 z-[500]', {
  variants: {
    position: {
      bottomRight: ['bottom-0 right-0'],
      bottomLeft: ['bottom-0 left-0'],
      topRight: ['top-0 right-0'],
      topLeft: ['top-0 left-0'],
    },
  },
  defaultVariants: {
    position: 'bottomRight',
  },
});

type NotificationToastProviderVariants = VariantProps<typeof toastViewportCva>;

export interface NotificationToastProviderProps extends NotificationToastProviderVariants {
  children: React.ReactNode;
  className?: string;
  defaultDuration?: number;
}

export function NotificationToastProvider({
  children,
  position,
  className,
  defaultDuration = 4000,
}: NotificationToastProviderProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return isClient ? (
    <Toast.Provider duration={defaultDuration}>
      <>{children}</>
      <Portal.Root className="absolute z-[60]">
        <Toast.Viewport className={toastViewportCva({ position, className })} />
      </Portal.Root>
    </Toast.Provider>
  ) : null;
}
