import { forwardRef } from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/core/util';

import { ComponentSize } from '../../../types';

const baseButtonStyle = 'inline-flex items-center justify-center w-fit outline-none text-TextS';
const primarySecondaryStyle = 'rounded-[100px] border font-ibmPlexMono min-w-[104px] uppercase';

const buttonCva = cva('', {
  variants: {
    size: {
      [ComponentSize.XS]: ['px-3 py-1.5 rounded-2xl'],
      [ComponentSize.SM]: ['px-4 py-2 rounded-2xl'],
      [ComponentSize.MD]: ['px-5 py-3 rounded-3xl'],
      [ComponentSize.LG]: ['px-6 py-4 rounded-3xl'],
    },
    intent: {
      primary: [
        baseButtonStyle,
        primarySecondaryStyle,
        'bg-blue-700 text-white border-transparent hover:bg-blue-800 active:bg-blue-900 focus:border-blue-700 disabled:bg-blue-200',
      ],
      secondary: [
        baseButtonStyle,
        primarySecondaryStyle,
        'text-blue-700 bg-transparent border-blue-300 hover:border-blue-400 active:border-blue-500 focus:border-blue-700 disabled:text-blue-200 disabled:border-blue-200',
      ],
      nav: [
        baseButtonStyle,
        'border-2 font-abcRepro',
        'text-blue-700 bg-transparent border-transparent hover:bg-blue-50 active:border-blue-500 focus:border-blue-500 disabled:border-transparent disabled:text-blue-200',
      ],
      pill: [
        baseButtonStyle,
        'font-abcRepro border',
        'text-blue-800 bg-blue-50 border-transparent hover:bg-blue-100 focus:bg-blue-100 focus:border-blue-700 active:bg-blue-200 active:border-transparent disabled:bg-blue-100 disabled:text-blue-200',
      ],
      warning: [
        baseButtonStyle,
        primarySecondaryStyle,
        'bg-mustard-800 text-white border-transparent hover:border-transparent hover:bg-mustard-900 focus:border-transparent focus:bg-mustard-900 active:border-transparent disabled:bg-mustard-200',
      ],
    },
  },
  defaultVariants: { intent: 'primary', size: ComponentSize.SM },
});

type ButtonVariants = VariantProps<typeof buttonCva>;

export interface ButtonProps
  extends ButtonVariants,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'focus'> {
  children: React.ReactNode;
  className?: string;
  stopPropagation?: boolean;
  dataCypress?: string;
  asChild?: boolean;
}

export const Button = forwardRef(
  (
    {
      children,
      className,
      intent,
      size = ComponentSize.MD,
      onClick,
      type = 'button',
      disabled = false,
      stopPropagation,
      dataCypress,
      asChild = false,
      ...props
    }: ButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          buttonCva({
            intent,
            size,
          }),
          className,
        )}
        onClick={(evt) => {
          (evt.target as HTMLButtonElement)?.blur();
          if (stopPropagation) {
            evt.stopPropagation();
            evt.preventDefault();
          }
          onClick?.(evt);
        }}
        type={type}
        disabled={disabled}
        data-cypress={dataCypress}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);
